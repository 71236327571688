const ACCESS_TOKEN = localStorage.getItem('funugAdmin@user');

// const apiHost = 'http://localhost:9554/funug-api/';
// const apiHost = 'http://localhost:9554/develop/';
const apiHost = 'https://funugapi.qubitsanalytic.com/funug-api/'


export default {

    async loginUser(username, password) {

        let data = {
            "username": username,
            "password": password,
        }

        try {
            let response = await fetch(apiHost + 'user.login',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });
            let result = await response.json();

            return result;
        } catch (error) {
            console.log(error)
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async userInfo(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'user/profile',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async updateUser(
        userId,
        firstName,
        lastName,
        userName,
        email,
        phoneNumber,
        profilePhoto,
        roleId,
        updatedBy
    ) {

        let data = {
            "first_name": firstName,
            "last_name": lastName,
            "username": userName,
            "email": email,
            "phone_number": phoneNumber,
            "profile_photo": profilePhoto,
            "role_id": roleId,
            "updated_by": updatedBy,
            "user_id": userId
        }
        try {
            let response = await fetch(apiHost + 'user.update',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async createRole(roleName, code) {

        let data = {
            "role_name": roleName,
            "code": code,
            // "permission_id": permissionId
        }
        try {
            let response = await fetch(apiHost + 'user/role.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async updateRole(role_id, role_name) {

        let data = {
            "role_name": role_name,
            "role_id": role_id
        }
        try {
            let response = await fetch(apiHost + 'user/role.update',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async getRoles(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'user/role/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async deleteRole(role_id, user_id) {

        let data = {
            "role_id": role_id,
            "user_id": user_id
        }
        try {
            let response = await fetch(apiHost + 'user/role.delete',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async listPermissions(user_id) {

        let data = {
            "user_id": user_id,
        }
        try {
            let response = await fetch(apiHost + 'permission/list',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async createPermission(permission, departmentModuleId, description) {

        let data = {
            "permission": permission,
            "module_id": departmentModuleId,
            "description": description,
        }
        try {
            let response = await fetch(apiHost + 'permission.create',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': ['Contacting server....'] }
            return connError;
        }

    },

    async countRoles(user_id)//POST
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'user/role.count',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listRecentUsers(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'user/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    //NOTIFICATIONS
    async getUserNotifications(user_id)//POST
    {

        let data = {
            "user_id": user_id,
        }

        try {
            let response = await fetch(apiHost + 'notification/list/all',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async readNotification(notification_id)//POST
    {

        let data = {
            "notification_id": notification_id,
        }
        try {
            let response = await fetch(apiHost + 'notification.read',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async resetPassword(password) {

        let data = {
            "password": password
        }

        try {
            let response = await fetch(apiHost + 'user/auth/password.reset',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listRecentRoles(user_id) {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'user/role/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countUnreadNotifications(user_id)//POST
    {

        let data = {
            "user_id": user_id,
        }

        try {
            let response = await fetch(apiHost + 'notification/unread.count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getRecentNotifications(user_id)//POST
    {

        let data = {
            "user_id": user_id,
        }

        try {
            let response = await fetch(apiHost + 'notification/list/recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getUreadNotifications(user_id, status)//POST
    {

        let data = {
            "user_id": user_id,
            "status": status
        }

        try {
            let response = await fetch(apiHost + 'notification/list/criteria/unread',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getNotificationConfigs()//GET
    {

        try {
            let response = await fetch(apiHost + 'notification/config',
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async turnOffNotifications(configuration_id)//POST
    {

        let data = {
            "configuration_id": configuration_id
        }

        try {
            let response = await fetch(apiHost + 'notification/app.off',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async turnOnNotifications(configuration_id)//POST
    {

        let data = {
            "configuration_id": configuration_id
        }

        try {
            let response = await fetch(apiHost + 'notification/app.on',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async turnOnSms(configuration_id)//POST
    {

        let data = {
            "configuration_id": configuration_id
        }

        try {
            let response = await fetch(apiHost + 'notification/sms.on',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async turnOffSms(configuration_id)//POST
    {

        let data = {
            "configuration_id": configuration_id
        }

        try {
            let response = await fetch(apiHost + 'notification/sms.off',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }
    },

    async getRoleInfo(roleId)//POST
    {

        let data = {
            'role_id': roleId
        }

        try {
            let response = await fetch(apiHost + 'user/role/info',

                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async countPermissionsPerRole(user_id, role_id)
    {

        let data = {
            "user_id": user_id,
            "role_id": role_id,
        }

        try {
            let response = await fetch(apiHost + 'role.permission.count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    //get event categories
    
    async getPriceCategories(user_id)
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'price.cat.list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getEventCategories(user_id)
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'tour.type.list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async createEvent(list_title, category, description, location,start_date,end_date,fileList,ticketPiceCategory,user_id)
    {

        let data = {
            "tour_name":list_title,
            "tour_location":location,
            "tour_start_date":start_date,
            "tour_end_date":end_date,
            "tour_desc":description,
            // "tour_price":ticket_price,
            "tour_image":fileList,
            "tour_price_and_cat":ticketPiceCategory,
            "tour_type_id":category,
            // "tour_price_cat":ticket_category,
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'tour.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            console.log("jim ",error)
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getRecentEvents(user_id)
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'tour.list.recent',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getLiveEvents(user_id,event_status)
    {

        let data = {
            "user_id": user_id,
            "tour_status":event_status
        }

        try {
            let response = await fetch(apiHost + 'tour.list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listUsers(user_id)
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'user/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async addNewUser(firstName, lastName, userName, userRole,phoneNumber, email,photo)

    {

        let data = {   

            "first_name": firstName,
            "last_name": lastName,
            "phone_number": phoneNumber,
            "username": userName,
            "profile_photo":photo,
            "role_id": userRole,
            "email":email
             }

        try {
            let response = await fetch(apiHost + 'user.create',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            console.log("new user ",error)
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async getVendorEvents(active,expired,user)
    {

        let data = {
            "expired": expired,
            "active":active,
            "user_id":user
        }

        try {
            let response = await fetch(apiHost + 'list.by.vendor',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async myTicketSales(user_id)
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'my.ticket.sales',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async mobileMoneyLists(user_id,page,stated)
    {

        let data = {
            "user_id": user_id,
            "page":page,
            "trans_state":stated
        }

        try {
            let response = await fetch(apiHost + 'payment/mobilemoney/list',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async myStatement(user_id)
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'my.statement',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async myBalance(user_id)
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'my.balance',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    
    async myCommission(user_id)
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'my.commission',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async myTotalEvents(user_id)
    {

        let data = {
            "user_id": user_id
        }

        try {
            let response = await fetch(apiHost + 'tour.count',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async varifyEvents(events,ref)
    {

        let data = {
            "event":events,
            "reference":ref
        }

        try {
            let response = await fetch(apiHost + 'ticket.validate',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async requestcashout(moneny,usernam,pass,uid)
    {

        let data = {
            "amount":moneny,
            "username":usernam,
            "password":pass,
            "user_id":uid
        }

        try {
            let response = await fetch(apiHost + 'request.cashout',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async approveCashoutRequest(request,usernam,pass,uid)
    {

        let data = {
            "request_id":request,
            "username":usernam,
            "password":pass,
            "approve_id":uid
        }

        try {
            let response = await fetch(apiHost + 'approve.cashout',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },

    async listCashoutRequests(user)
    {

        let data = {
            "user_id":user
        }

        try {
            let response = await fetch(apiHost + 'list.cashout',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + ACCESS_TOKEN,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data),
                });

            let result = await response.json();

            return result;
        } catch (error) {
            let connError = { 'status': 'conn', 'details': { 'message': 'Contacting server....', 'content': false } }
            return connError;
        }

    },
    
}