import React, { Component } from 'react'

class ErrorPage extends Component {

  render() {
    return (

<body>
    <div class="app">
        <div class="container-fluid">
            <div class="d-flex full-height p-v-20 flex-column justify-content-between">
                <div class="d-none d-md-flex p-h-40">
                <img class="img-fluid" alt="" src="assets/images/logo/black-logo.png" style={{ height: '60px', width: '120px', marginTop: '10px' }} />
                </div>
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-6">
                            <div class="p-v-30">
                                <h1 class="font-weight-semibold display-1 text-primary lh-1-2">404</h1>
                                <h2 class="font-weight-light font-size-30">Whoops! Looks like you got lost</h2>
                                <p class="lead m-b-30">We couldnt find what you were looking for.</p>
                                <a href="/" class="btn btn-primary btn-tone">Go Back</a>
                            </div>
                        </div>
                        <div class="col-md-6 m-l-auto">
                            <img class="img-fluid" src="assets/images/others/error-1.png" alt=""/>
                        </div>
                    </div>
                </div>
                <div class="d-none d-md-flex  p-h-40 justify-content-between">
                    <span class="">© 2023 Funug</span>
                    
                </div>
            </div>
        </div>
    </div>

</body>

    )
  }
}
export default ErrorPage
