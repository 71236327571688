import React, { Component } from 'react'
import { DatePicker, Upload, message, Modal, Input, Select, Button, Form, Space } from 'antd';
import Icon from '@ant-design/icons';
import Nav from './common/Nav';
import ajax from '../utils/ajax'
import functions from '../utils/functions'
import Loader from './common/Loader';
import Notification from './common/Notification';
import 'antd/dist/antd.css'
import Header from './common/Header';


const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

class NewUser extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: '',
        photo: '',
        roleName: '',
        firstName: '',
        lastName: '',
        username: '',
        phoneNumber: '',
        email: '',
        userRoles: false,
        size: 'default',
        previewVisible: false,
        previewImage: '',
        fileList: [],
        profilePhoto: "",

    }

    componentDidMount() {
        this.getUserRoles();
    }

    getUserRoles = async () => {
        const server_response = await ajax.getRoles(this.state.userId);
        const roleOptions = []

        //console.log("respon ",server_response)

        if (server_response.status === "OK") {

            server_response.details.content.list.map((rols) =>
                roleOptions.push(<Option value={rols.role_id}>{rols.role_name}</Option>),
            )

            this.setState({
                eventCategory: roleOptions
            })

        } else {
            this.setState({
                eventCategory: "404"
            })
        }
    }


    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleChange = ({ fileList }) => {
        // console.log("images ", fileList[0])
        this.setState({ fileList })

        if (fileList[0] != null) {

            let reader = new FileReader();
            reader.readAsDataURL(fileList[0].originFileObj);
            reader.onload = (e) => {
                // console.log("images zz ",e.target.result)
                this.setState({
                    profilePhoto: e.target.result
                })
            }
        }

    }

    newHandlePreview = (file) => {
        // console.log("images pp ",file)
    }

    handleFirstName = (value) => {

        this.setState({
            firstName: value.target.value
        })
    }

    handleLastName = (value) => {

        this.setState({
            lastName: value.target.value
        })
    }

    handleUserName = (value) => {

        this.setState({
            username: value.target.value
        })
    }

    handlePhoneNumber = (value) => {

        this.setState({
            phoneNumber: value.target.value
        })
    }

    handleEmail = (value) => {

        this.setState({
            email: value.target.value
        })
    }

    handleUserRole = (value) => {
        if (value != null) {

            this.setState({
                roleName: value
            })
        } else {
            // console.log("tadaa ", value)
            this.setState({
                roleName: ""
            })
        }
    }

    onNewEvent = async (event) => {
        event.preventDefault()

        const { fileList, roleName, firstName, lastName, username, phoneNumber, email, profilePhoto } = this.state

        if (firstName.length > 0 &&
            lastName.length > 0 &&
            username.length > 0 &&
            roleName.length > 0 &&
            phoneNumber.length > 0 &&
            email.length > 0,
            fileList.length > 0) {

            this.setState({
                type: '',
                info: '',
                load: <Loader />
            })

            const server_response = await ajax.addNewUser(firstName, lastName, username, roleName, phoneNumber, email, profilePhoto)

            if (server_response.status === "OK") {
                this.setState({
                    type: 'alert alert-success',
                    info: server_response.details.message,
                    load: '',

                    list_title: "",
                    category: "",
                    description: "",
                    location: "",
                    // ticket_category: "",
                    // ticket_price: "",
                })

                setTimeout(function () {
                    window.location.reload();
                }, 10000);

            } else {

                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: '',
                })

            }


        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'Please be advised to fill in all required fields - All fields are required '
            })
        }

    }


    render() {

        const { previewVisible, previewImage, fileList } = this.state;

        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <>

                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">
                                    <div class="page-header no-gutters has-tab">
                                        <h2 class="font-weight-normal">Add New User</h2>
                                    </div>

                                    <div style={{ marginInline: '20px', marginTop: '15px' }}>
                                        {this.state.load}
                                        <Notification
                                            message={{
                                                type: this.state.type,
                                                info: this.state.info,
                                            }}
                                        />
                                    </div>

                                    <div class="container">
                                        <div class="tab-content m-t-15">
                                            <div class="tab-pane fade show active" id="tab-account" >
                                        <form method="post" onSubmit={this.onNewEvent}>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">Basic Infomation</h4>
                                                        </div>
                                                        <div class="card-body">

                                                            <div class="form-row">
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="userName">First Name:</label>
                                                                    <Input class="input-text" style={{ width: '100%' }} size="large" placeholder="First Name" onChange={this.handleFirstName} />

                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="userName">Last Name:</label>
                                                                    <Input class="input-text" style={{ width: '100%' }} size="large" placeholder="Last Name" onChange={this.handleLastName} />

                                                                </div>

                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="userName">Username:</label>
                                                                    <Input class="input-text" style={{ width: '100%' }} size="large" placeholder="Username" onChange={this.handleUserName} />

                                                                </div>


                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="userName">Phone Number:</label>
                                                                    <Input class="input-text" style={{ width: '100%' }} size="large" placeholder="Phone Number" onChange={this.handlePhoneNumber} />

                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="userName">Email:</label>
                                                                    <Input class="input-text" style={{ width: '100%' }} size="large" placeholder="user email" onChange={this.handleEmail} />

                                                                </div>

                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="userName">User Role:</label>
                                                                    <Select
                                                                        size='large'
                                                                        // className='selectpicker search-fields'
                                                                        showSearch
                                                                        style={{ width: '100%' }}
                                                                        onChange={this.handleUserRole}
                                                                        placeholder="select user role"
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                                        filterSort={(optionA, optionB) =>
                                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                                        }
                                                                    >
                                                                        {this.state.eventCategory}

                                                                    </Select>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">User Photo</h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="form-row">
                                                                <div class="form-group col-md-3">
                                                                    <div className="clearfix">
                                                                        <Upload
                                                                            action=""
                                                                            listType="picture-card"
                                                                            fileList={fileList}
                                                                            maxCount={1}
                                                                            onPreview={this.handlePreview}
                                                                            onChange={this.handleChange}
                                                                        >
                                                                            {fileList.length >= 1 ? null : uploadButton}
                                                                        </Upload>
                                                                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                                                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                                                        </Modal>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">Create New User</h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="form-row">

                                                                <div class="form-group col-md-3">
                                                                    <button type="submit" class="btn btn-primary m-t-30">Add User</button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>

            </>
        )
    }
}
export default NewUser