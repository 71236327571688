import React, { Component } from 'react'
import ajax from '../utils/ajax';
import functions from '../utils/functions';
import { Table, Select } from 'antd';
import 'antd/dist/antd.css';
import Nav from './common/Nav';
import Header from './common/Header';
import Loader from './common/Loader';
import Notification from './common/Notification';

const { Option } = Select;


class Verify extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: '',
        photo: '',
        ticket_cat: '',
        ticket_date: '',
        ticket_event: '',
        ticket_email: '',
        ticket_name: '',
        ticket_phone:'',
        varify_event: '',
        varify_ref: ''
    }

    componentDidMount() {
        this.getUserProfile();
        this.getVendorEvents(0, 0)
    }

    getVendorEvents = async (active, expired) => {
        const server_response = await ajax.getVendorEvents(active, expired, this.state.roleName == "ADMIN" || this.state.roleName == "OPERATION" ? "0" : this.state.userId);
        const vendorEves = []

        if (server_response.status === "OK") {

            server_response.details.content.list.map((tren) => {
                vendorEves.push(<Option value={tren.tour_id}>{tren.tour_name}</Option>)
            })

            this.setState({
                allMyEvents: vendorEves
            })

        } else {

            this.setState({
                allMyEvents: "404"
            })
        }

    }

    getUserProfile = async () => {
        const server_response = await ajax.userInfo(this.state.userId);

        if (server_response.status === "OK") {

            //console.log(" sss sss ", server_response.details.content)

            this.setState({
                username: server_response.details.content.username,
                photo: server_response.details.content.profile_photo.file_path,
                roleName: server_response.details.content.user_role.code,
                first_name: server_response.details.content.first_name,
                last_name: server_response.details.content.last_name,
                phone: server_response.details.content.phone_number,
                email: server_response.details.content.email
            })

        } else {
            // this.setState({
            //     livetEvents: "404"
            // })
        }
    }

    handleRef = (value) => {

        this.setState({
            varify_ref: value.target.value
        })
    }

    handleTour = (value) => {
        if (value != null) {

            this.setState({
                varify_event: value
            })
        } else {
            // console.log("tadaa ", value)
            this.setState({
                varify_event: ""
            })
        }
    }

    onVerifyEvent = async (event) => {
        event.preventDefault()

        const { varify_ref, varify_event } = this.state

        if (varify_ref.length > 0 &&
            varify_event.length > 0) {

            this.setState({
                type: '',
                info: '',
                load: <Loader />
            })

            const server_response = await ajax.varifyEvents(varify_event, varify_ref)

            if (server_response.status === "OK") {

                //console.log("yutysssss", server_response)

                this.setState({
                    type: 'alert alert-success',
                    info: server_response.details.message,
                    load: '',

                    //
                    ticket_cat: server_response.details.content.ticket_cat,
                    ticket_date: server_response.details.content.ticket_date,
                    ticket_email: server_response.details.content.ticket_email,
                    ticket_name: server_response.details.content.ticket_name,
                    ticket_phone:server_response.details.content.ticket_phone,
                    ticket_event:server_response.details.content.ticket_event,
                    //

                    varify_ref: "",
                })

            } else {

                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: '',
                })

            }


        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'Please be advised to fill in all required fields - All fields are required '
            })
        }

    }


    render() {
        return (


            <>


                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">
                                    <div class="page-header no-gutters has-tab">
                                        <h2 class="font-weight-normal">Check Ticket</h2>
                                        <ul class="nav nav-tabs" >
                                            <li class="nav-item">
                                                <a class="nav-link active" data-toggle="tab" href="#tab-network">Checking Center</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="container">
                                        <div class="tab-content m-t-15">
                                            <div class="tab-pane fade show active" id="tab-network" >
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h4 class="card-title">Please Pay Attention [ A ticket is only validated once ]</h4>

                                                        <div style={{ marginTop: '15px' }}>
                                                            {this.state.load}
                                                            <Notification
                                                                message={{
                                                                    type: this.state.type,
                                                                    info: this.state.info,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="card-body">
                                                        <form method="post" onSubmit={this.onVerifyEvent}>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity">Select Event:</label>
                                                                    <Select
                                                                        size='large'
                                                                        // className='selectpicker search-fields'
                                                                        showSearch
                                                                        style={{ width: '100%' }}
                                                                        onChange={this.handleTour}
                                                                        placeholder="Search to Select"
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                                        filterSort={(optionA, optionB) =>
                                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                                        }
                                                                    >
                                                                        {this.state.allMyEvents}

                                                                    </Select>
                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity">Ticket Ref No:</label>
                                                                    <input type="text" onChange={this.handleRef} value={this.state.varify_ref} class="form-control" id="stateCity" />
                                                                </div>

                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity"> &nbsp; &nbsp;</label><br />
                                                                    <button class="btn btn-primary ">Process Ticket</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div class="card">
                                                    <div class="card-header">
                                                        <h4 class="card-title">Results:</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <form>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity">Event:</label>
                                                                    <input type="text" class="form-control" value={this.state.ticket_event} id="stateCity" readOnly />

                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity">Client Name:</label>
                                                                    <input type="text" class="form-control" value={this.state.ticket_name} id="stateCity" readOnly />
                                                                </div>

                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity">Client Phone:</label>
                                                                    <input type="text" class="form-control" value={this.state.ticket_phone} id="stateCity" readOnly />
                                                                </div>

                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity">Client Email:</label>
                                                                    <input type="text" class="form-control" value={this.state.ticket_email} id="stateCity" readOnly />

                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity">Event Date:</label>
                                                                    <input type="text" class="form-control" value={this.state.ticket_date} id="stateCity" readOnly />
                                                                </div>

                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity">Ticket Category:</label>
                                                                    <input type="text" class="form-control" value={this.state.ticket_cat} id="stateCity" readOnly />
                                                                </div>

                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>


            </>


        )
    }
}
export default Verify
