import React, { Component } from 'react'
import { DatePicker, Upload, message, Modal, Input, Select, Button, Form, Space } from 'antd';
import Icon from '@ant-design/icons';
import Nav from './common/Nav';
import ajax from '../utils/ajax'
import functions from '../utils/functions'
import Loader from './common/Loader';
import Notification from './common/Notification';
import 'antd/dist/antd.css'
import Header from './common/Header';
import AddTicketTypes from './common/AddTicketTypes';


const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Option } = Select;

class NewEvent extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: '',
        photo: '',
        roleName: '',
        list_title: '',
        category: '',
        description: '',
        eventCategory: false,
        priceCategory: false,
        start_date: '',
        end_date: '',
        location: '',
        ticket_category: '',
        ticket_price: '',
        address: '',
        size: 'default',
        previewVisible: false,
        previewImage: '',
        fileList: [],
        profilePhoto: "",
        ticketPiceCategory: [],
        btnState:false

    }

    componentDidMount() {
        this.getEventCategories();
        this.getTicketCategories();
    }

    getEventCategories = async () => {
        const server_response = await ajax.getEventCategories('1');
        const catOptions = []

        if (server_response.status === "OK") {

            server_response.details.content.list.map((cats) =>
                catOptions.push(<Option value={cats.tour_type_id}>{cats.tour_type_name}</Option>),
            )

            this.setState({
                eventCategory: catOptions
            })

        } else {
            this.setState({
                eventCategory: "404"
            })
        }
    }

    getTicketCategories = async () => {
        const server_response = await ajax.getPriceCategories(this.state.userId);
        const catOptions = []

        if (server_response.status === "OK") {

            server_response.details.content.list.map((cats) =>
                catOptions.push(<Option value={cats.price_cat_id}>{cats.price_cat_name}</Option>),

            )

            this.setState({
                priceCategory: catOptions
            })

        } else {
            this.setState({
                priceCategory: "404"
            })
        }
    }

    handleCancel = () => this.setState({ previewVisible: false })

    handlePreview = (file) => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    }

    handleChange = ({ fileList }) => {
        // console.log("images ", fileList[0])
        this.setState({ fileList })

        if (fileList[0] != null) {

            let reader = new FileReader();
            reader.readAsDataURL(fileList[0].originFileObj);
            reader.onload = (e) => {
                // console.log("images zz ",e.target.result)
                this.setState({
                    profilePhoto: e.target.result
                })
            }
        }

    }

    newHandlePreview = (file) => {
        // console.log("images pp ",file)
    }

    handleTitle = (value) => {

        this.setState({
            list_title: value.target.value
        })
    }

    handleTourCategory = (value) => {
        if (value != null) {

            this.setState({
                category: value
            })
        } else {
            // console.log("tadaa ", value)
            this.setState({
                category: ""
            })
        }
    }

    handleDescription = (value) => {
        this.setState({
            description: value.target.value
        })
    }

    handleDate = (dates, dateStrings) => {

        if (dateStrings != "") {

            this.setState({
                start_date: dateStrings[0],
                end_date: dateStrings[1]

            })
        } else {

            this.setState({
                start_date: "",
                end_date: ""

            })
        }
    }

    handleLocation = (value) => {
        this.setState({
            location: value.target.value
        })
    }

    handleTicketCategory = (value) => {

        if (value != null) {
            this.setState({
                ticket_category: value
            })
        } else {
            this.setState({
                ticket_category: ""
            })
        }
    }

    handleTicketPrice = (value) => {
        this.setState({
            ticket_price: value.target.value
        })
    }

    handleCallback = (childData) => {
        //console.log("  sfada ",childData )
        this.setState({
            ticketPiceCategory: childData
        })
    }

    onNewEvent = async (event) => {
        event.preventDefault()
        //const { list_title, category, description, location, ticket_category, ticket_price, start_date, end_date, fileList, profilePhoto } = this.state

        const { list_title, category, description, location, start_date, end_date, fileList, profilePhoto, ticketPiceCategory } = this.state

        if (list_title.length > 0 &&
            category.length > 0 &&
            description.length > 0 &&
            location.length > 0 &&
            start_date[0].length > 0 &&
            // ticket_category.length > 0 &&
            // ticket_price.length > 0 &&
            ticketPiceCategory.length > 0,
            fileList.length > 0) {

            this.setState({
                type: '',
                info: '',
                load: <Loader />,
                btnState:true
            })

            const server_response = await ajax.createEvent(list_title, category, description, location, start_date, end_date, profilePhoto, ticketPiceCategory, this.state.userId)

            if (server_response.status === "OK") {
                this.setState({
                    type: 'alert alert-success',
                    info: server_response.details.message,
                    load: '',

                    list_title: "",
                    category: "",
                    description: "",
                    location: "",
                    btnState:false
                    // ticket_category: "",
                    // ticket_price: "",
                })

                setTimeout(function () {
                    window.location.reload();
                }, 2000);

            } else {

                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: '',
                    btnState:false
                })

            }


        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'Please be advised to fill in all required fields - All fields are required '
            })
        }

    }


    render() {

        const { previewVisible, previewImage, fileList } = this.state;

        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );

        return (
            <>

                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">
                                    <div class="page-header no-gutters has-tab">
                                        <h2 class="font-weight-normal">Add New Event / Tour / Adventure</h2>
                                    </div>

                                    <div style={{ marginInline: '20px', marginTop: '15px' }}>
                                        {this.state.load}
                                        <Notification
                                            message={{
                                                type: this.state.type,
                                                info: this.state.info,
                                            }}
                                        />
                                    </div>

                                    <div class="container">
                                        <div class="tab-content m-t-15">
                                            <div class="tab-pane fade show active" id="tab-account" >
                                                <form method="post" onSubmit={this.onNewEvent}>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">Basic Infomation</h4>
                                                        </div>
                                                        <div class="card-body">

                                                            <div class="form-row">
                                                                <div class="form-group col-md-6">
                                                                    <label class="font-weight-semibold" for="userName">Event Title:</label>
                                                                    <Input class="input-text" style={{ width: '100%' }} size="large" placeholder="Title" onChange={this.handleTitle} />
                                                                </div>
                                                                <div class="form-group col-md-6">
                                                                    <label class="font-weight-semibold" for="userName">Event Category:</label>
                                                                    <Select
                                                                        size='large'
                                                                        // className='selectpicker search-fields'
                                                                        showSearch
                                                                        style={{ width: '100%' }}
                                                                        onChange={this.handleTourCategory}
                                                                        placeholder="Search to Select"
                                                                        optionFilterProp="children"
                                                                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                                                        filterSort={(optionA, optionB) =>
                                                                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                                                        }
                                                                    >
                                                                        {this.state.eventCategory}

                                                                    </Select>
                                                                </div>

                                                                <div class="form-group col-md-6">
                                                                    <label class="font-weight-semibold" for="userName">Event Location:</label>
                                                                    <Input class="input-text" style={{ width: '100%' }} size="large" placeholder="Location" onChange={this.handleLocation} />
                                                                </div>

                                                                <div class="form-group col-md-6">
                                                                    <label class="font-weight-semibold" for="userName">&nbsp;</label>
                                                                    <Input class="input-text" style={{ width: '100%' }} size="large" disabled />
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">Event / Adventure / Tour Description</h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <label class="font-weight-semibold" >Description:</label>
                                                                    <TextArea
                                                                        showCount
                                                                        maxLength={500}
                                                                        style={{ height: '100%', width: '100%', resize: 'none' }}
                                                                        onChange={this.handleDescription}
                                                                        placeholder="About the adventure"
                                                                    />

                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">Event Date</h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <label class="font-weight-semibold" for="oldPassword">Select Dates:</label>
                                                                    <RangePicker size='large' onChange={this.handleDate} style={{ width: "100%", marginTop: "4px" }} />
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">Event / Tour / Adventure Poster / Image</h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="form-row">
                                                                <div class="form-group col-md-3">
                                                                    <div className="clearfix">
                                                                        <Upload
                                                                            action=""
                                                                            listType="picture-card"
                                                                            fileList={fileList}
                                                                            maxCount={1}
                                                                            onPreview={this.handlePreview}
                                                                            onChange={this.handleChange}
                                                                        >
                                                                            {fileList.length >= 1 ? null : uploadButton}
                                                                        </Upload>
                                                                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                                                                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                                                        </Modal>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="card">
                                                        <div class="card-header">
                                                            <h4 class="card-title">Select Ticket Category(s)</h4>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <AddTicketTypes parentCallback={this.handleCallback} />
                                                                </div>
                                                                <div class="form-group col-md-3">
                                                                    <button type="submit" disabled={this.state.btnState} class="btn btn-primary m-t-30">Add Fun Event</button>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>

            </>
        )
    }
}
export default NewEvent