import React, { Component } from 'react'
// import { Modal, Select, Table } from 'antd'
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import ajax from '../../utils/ajax'
import Loader from '../common/Loader';
import Notification from '../common/Notification';


class Reset extends Component {

    state = {
        confirmPassword: '',
        password: '',
        type: '',
        info: '',
        load: '',
    }

    onPressReset = async (event) => {
        event.preventDefault();
        const { password, confirmPassword } = this.state;

        if (
            password.length > 0 &&
            confirmPassword.length > 0
        ) {

            if (password !== confirmPassword) {
                this.setState({
                    type: 'alert alert-danger',
                    info: "Passwords Don't match",
                    load: '',
                })
            } else {

                this.setState({
                    type: '',
                    info: '',
                    load: <Loader />
                })

                const server_response = await ajax.resetPassword(password);
                if (server_response.status === "OK") {
                    localStorage.removeItem('funugAdmin@user');
                    window.location.replace('/');

                } else {
                    this.setState({
                        type: 'alert alert-danger',
                        info: server_response.details.message,
                        load: '',
                    })
                }
            }
        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'Both password fields Required',
                load: '',
            })
        }


    }

    onChangePassword = (event) => {
        this.setState({ password: event.target.value })
    }

    onConfirmPassword = (event) => {
        this.setState({ confirmPassword: event.target.value })
    }

    render() {
        return (

            <body>
                <div class="app">
                    <div class="container-fluid p-h-0 p-v-20 bg full-height d-flex" style={{ backgroundImage: "url(" + 'assets/images/others/login-3.png' + ")" }}>
                        <div class="d-flex flex-column justify-content-between w-100">
                            <div class="container d-flex h-100">
                                <div class="row align-items-center w-100">
                                    <div class="col-md-7 col-lg-5 m-h-auto">
                                        <div class="card shadow-lg">
                                            <div class="card-body">
                                            {this.state.load}
                                        <Notification
                                            message={{
                                                type: this.state.type,
                                                info: this.state.info,
                                            }}
                                        />
                                                <div class="d-flex align-items-center justify-content-between m-b-30">
                                                <img class="img-fluid" alt="" src="assets/images/logo/black-logo.png" style={{ height: '60px', width: '110px', marginTop: '10px' }} />
                                                    <h3>Reset password</h3>
                                                </div>
                                                <form method="post" onSubmit={this.onPressReset}>
                                                    <div class="form-group">
                                                        <label class="font-weight-semibold" for="userName">Password:</label>
                                                        <div class="input-affix">
                                                        <i class="prefix-icon fas fa-lock"></i>
                                                            <input type="password" name="email" class="form-control" onChange={this.onChangePassword} placeholder="New Password" />
                                                        </div>
                                                    </div>
                                                    
                                                    <div class="form-group">
                                                        <label class="font-weight-semibold" for="password">Repeat Password:</label>
                                                        <div class="input-affix">
                                                        <i class="prefix-icon fas fa-lock"></i>
                                                            <input type="password" onChange={this.onConfirmPassword} name="Password"  class="form-control" placeholder="New Password" />

                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                        
                                                            <button class="btn btn-primary">Reset Password</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-none d-md-flex p-h-40 justify-content-between">
                                <span class="">© 2023 Funug</span>
                            </div>
                        </div>
                    </div>
                </div>

            </body>

        )
    }
}

export default Reset
