import React, { Component } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import functions from '../../utils/functions';

class Header extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: functions.loggedInUser(),

    }

    onLogout = () => {
        localStorage.removeItem('funugAdmin@user');
        window.location.replace('/');
    }

    render() {
        const roleList = functions.roleGuard()
        return (
            <>

                {/* <!-- Header START --> */}
                <div class="header">
                    <div class="logo logo-dark">
                        <a href="/">
                            <img src="assets/images/logo/black-logo.png" alt="Logo" style={{ height: '50px', width: '110px', marginTop: '10px' }} />
                            <img class="logo-fold" src="assets/images/logo/logos.png" alt="Logo" style={{ height: '40px', width: '50px', marginTop: '10px', marginLeft: '10px' }} />
                        </a>
                    </div>
                    <div class="logo logo-white">
                        <a href="/">
                            <img src="assets/images/logo/black-logo.png" alt="Logo" style={{ height: '50px', width: '110px', marginTop: '10px' }} />
                            <img class="logo-fold" src="assets/images/logo/logos.png" alt="Logo" style={{ height: '50px', width: '110px', marginTop: '10px' }} />
                        </a>
                    </div>
                    <div class="nav-wrap">
                        <ul class="nav-left">
                            <li class="desktop-toggle">
                                <a href="javascript:void(0);">
                                <img src="assets/images/drawer.png" style={{ height: '40px', width: '30px'}} />
                                </a>
                            </li>
                            <li class="mobile-toggle">
                                <a href="javascript:void(0);">
                                <img src="assets/images/drawer.png" style={{ height: '40px', width: '30px'}} />
                                </a>
                            </li>
                            
                        </ul>
                        <ul class="nav-right">

                            <li class="dropdown dropdown-animated scale-left">
                                <div class="pointer" data-toggle="dropdown">
                                    <div class="avatar avatar-image  m-h-10 m-r-15">
                                        <img src="assets/images/avatars/avatar-1.png" alt="" />
                                    </div>
                                </div>
                                <div class="p-b-15 p-t-20 dropdown-menu pop-profile">
                                    <div class="p-h-20 p-b-15 m-b-10 border-bottom">
                                        <div class="d-flex m-r-50">
                                            <div class="avatar avatar-lg avatar-image">
                                                <img src="assets/images/avatars/avatar-1.png" alt="" />
                                            </div>
                                            <div class="m-l-10">
                                                <p class="m-b-0 text-dark font-weight-semibold">{this.state.username}</p>
                                                <p class="m-b-0 opacity-07">{this.state.roleName}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="/account-settings" class="dropdown-item d-block p-h-15 p-v-10">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>
                                                <i class="fas fa-cogs opacity-04 font-size-16"></i>

                                                <span class="m-l-10">Account Setting</span>
                                            </div>
                                            <i class="anticon font-size-10 anticon-right"></i>
                                        </div>
                                    </a>

                                    <a href="javascript:void(0);" onClick={this.onLogout} class="dropdown-item d-block p-h-15 p-v-10">
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div>
                                                <i class="anticon opacity-04 font-size-16 anticon-logout"></i>
                                                <i class="fas fa-lock opacity-04 font-size-16"></i>

                                                <span class="m-l-10" >Logout</span>
                                            </div>
                                            <i class="anticon font-size-10 anticon-right"></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <!-- Header END --> */}

            </>
        )
    }
}
export default Header
