import React, { Component } from 'react'
import PropagateLoader from "react-spinners/PropagateLoader";

class Loader extends Component {
  render() {
    return (
        <div>
        <div class="text-center">
            <PropagateLoader size="15" color='#17a2b8'/>
        </div>
        <br/>
        </div>
    )
  }
}

export default Loader