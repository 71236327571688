import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { DatePicker, Upload, message, Modal, Input, Select, Button, Form, Space } from 'antd';
import ajax from '../utils/ajax'
import functions from '../utils/functions'
import Header from './common/Header';
import Nav from './common/Nav';
import { Bars } from 'react-loader-spinner';
import NoDataFound from './common/NoDataFound';
import Notification from './common/Notification'
import Loader from './common/Loader';


class Cashouts extends Component {

    state = {
        userId: functions.sessionGuard(),
        roleName: functions.roleGuard(),
        username: functions.loggedInUser(),
        photo: '',
        first_name: '',
        password: '',
        allRequestCash: false,
        amounts: '',
        amountNews: '',
        passwords: '',
        butt:false
    }

    componentDidMount() {
        this.getCashoutRequests();
    }

    getCashoutRequests = async () => {
        const server_response = await ajax.listCashoutRequests(this.state.roleName == "ADMIN" || this.state.roleName == "OPERATION" ? "0" : this.state.userId);
        const requestCash = []

        if (server_response.status === "OK") {
            // console.log("yutysss", server_response)
            server_response.details.content.list.map((tren) => {
                requestCash.push({
                    request_id: tren.request_id,
                    request_name: tren.request_user,
                    request_amount: tren.request_amount,
                    request_date: tren.request_date.short_date,
                    request_app_date: tren.request_approve_date.short_date,
                    request_status: tren.request_status,
                    request_approve: tren.request_approve
                }
                )
            })

            this.setState({
                allRequestCash: requestCash
            })

        } else {
            // console.log("yuty", server_response)
            this.setState({
                allRequestCash: []
            })
        }
    }

    handleProcess = (request_amount, request_name, request_id) => {

        this.setState({
            "amounted": request_amount,
            "requesty": request_name,
            "ided": request_id
        })
    }

    handlePassword = (value) => {

        this.setState({
            password: value.target.value
        })
    }

    onProcessCashout = async (event) => {
        event.preventDefault()

        const { password } = this.state

        if (password.length > 0) {

            this.setState({
                type: '',
                info: '',
                butt: true,
                load: <Loader />
            })

            const server_response = await ajax.approveCashoutRequest(this.state.ided, this.state.username, password, this.state.userId)

            if (server_response.status === "OK") {

                //console.log("yutysssss", server_response)

                this.setState({
                    type: 'alert alert-success',
                    info: server_response.details.message,
                    load: ''
                })

                setTimeout(function () {
                    window.location.reload();
                }, 5000);

            } else {

                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: '',
                    butt: false
                })

            }



        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'All fields are required ' + password,
                butt: false
            })
        }

    }

    handleAmounts = (value) => {

        this.setState({
            amounts: value.target.value
        })
    }

    handleAmountNews = (value) => {

        this.setState({
            amountNews: value.target.value
        })
    }

    handlePasswords = (value) => {

        this.setState({
            passwords: value.target.value
        })
    }

    onRequestCashout = async (event) => {
        event.preventDefault()

        const { amounts, amountNews, passwords } = this.state

        if (amounts.length > 0 &&
            amountNews.length > 0 &&
            passwords.length > 0) {

            if (amounts !== amountNews) {
                this.setState({
                    type: 'alert alert-danger',
                    info: "Amounts Don't match",
                    load: '',
                })
            } else {

                this.setState({
                    type: '',
                    info: '',
                    butt:true,
                    load: <Loader />
                })

                const server_response = await ajax.requestcashout(amounts,this.state.username, passwords,this.state.userId)

                if (server_response.status === "OK") {

                    //console.log("yutysssss", server_response)

                    this.setState({
                        type: 'alert alert-success',
                        info: server_response.details.message,
                        load: ''
                    })

                    setTimeout(function () {
                        window.location.reload();
                    }, 5000);

                } else {

                    this.setState({
                        type: 'alert alert-danger',
                        info: server_response.details.message,
                        load: '',
                        butt:false
                    })

                }

            }

        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'All fields are required ',
                butt:false
            })
        }

    }

    render() {
        const myevents = this.state.allRequestCash
        const roleList = functions.roleGuard()

        return (
            <>

                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <h5>All Cashouts</h5>

                                                        <div>

                                                            <button type="button" class="btn btn-primary" data-toggle="modal"
                                                                data-target="#exampleModalScrollableTwo">
                                                                Request Cashout
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <input type="text" class="form-control" placeholder="Search" />
                                                        </div>
                                                        <div class="col-md-3">

                                                        </div>
                                                        <div class="col-md-5"></div>
                                                    </div>

                                                    <div class="m-t-30">


                                                        <div class="row" id="list-view">
                                                            <div class="col-md-12">
                                                                {/* <div class="card">
                                                                    <div class="card-body"> */}
                                                                <div class="table-responsive">
                                                                    {Array.isArray(myevents) && myevents.length === 0 ? (<NoDataFound term={{ tellme: this.state.serachTerm }} />) : (
                                                                        <>
                                                                            <table class="table table-hover table-bordered table table-md">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ fontWeight: 'bold' }}>Requesting User</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Requested Amount</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Request Date</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Approve Date</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Status</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Approved By</th>
                                                                                        {roleList === "ADMIN" &&
                                                                                            <th style={{ fontWeight: 'bold' }}>Action</th>
                                                                                        }
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>

                                                                                    {Array.isArray(myevents) ?
                                                                                        (
                                                                                            myevents.map((item, key) =>

                                                                                                <tr key={key}>
                                                                                                    <td>
                                                                                                        <div class="media align-items-center">
                                                                                                            <div class="avatar avatar-image">
                                                                                                                <img src="assets/images/avatars/avatar-1.png" alt="listing-img" class="img-fluid" />                                                                                                </div>
                                                                                                            <div class="media-body m-l-15">
                                                                                                                <h6 class="mb-0">{item.request_name}</h6>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td> <i class="flaticon-pin"></i>  {item.request_amount} UGX</td>
                                                                                                    <td>{item.request_date}</td>
                                                                                                    <td>{item.request_app_date}</td>
                                                                                                    <td>{item.request_status == "0" ? <span class="badge badge-pill badge-warning">Pending</span> : <span class="badge badge-pill badge-success">Approved</span>} </td>
                                                                                                    <td>{item.request_approve == null ? <span class="badge badge-pill badge-info">Not Approved</span> : item.request_approve}</td>

                                                                                                    {(roleList === "ADMIN" || roleList === "OPERATION") &&

                                                                                                        <>
                                                                                                            <td class="text-right">
                                                                                                                {item.request_status == "0" ?


                                                                                                                    <button type="button" class="btn btn-danger btn-tone" data-toggle="modal"
                                                                                                                        onClick={(e) => { this.handleProcess(item.request_amount, item.request_name, item.request_id) }}
                                                                                                                        data-target="#exampleModalScrollable">
                                                                                                                        Process Cashout
                                                                                                                    </button>


                                                                                                                    :
                                                                                                                    <button type="button" class="btn btn-success btn-tone">
                                                                                                                        Processed
                                                                                                                    </button>
                                                                                                                }

                                                                                                            </td>
                                                                                                        </>
                                                                                                    }
                                                                                                </tr>

                                                                                            )
                                                                                        ) :
                                                                                        (
                                                                                            <tr class="responsive-table">
                                                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                                    <Bars
                                                                                                        height="70"
                                                                                                        width="100"
                                                                                                        color="#F21313"
                                                                                                        ariaLabel="bars-loading"
                                                                                                        wrapperStyle={{}}
                                                                                                        wrapperClass=""
                                                                                                        visible={true}
                                                                                                    />
                                                                                                </div>
                                                                                            </tr>
                                                                                        )

                                                                                    }

                                                                                </tbody>
                                                                            </table>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {/* </div>
                                                                </div> */}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="modal fade" id="exampleModalScrollable" data-backdrop="static" data-keyboard="false">
                                        <div class="modal-dialog modal-dialog-scrollable">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalScrollableTitle"><b>Process Cashout</b><br /><b style={{ color: 'red' }}>[Action not reversible once approved]</b></h5>
                                                    <button type="button" class="close" data-dismiss="modal">
                                                        <i class="far fa-window-close"></i>
                                                    </button>
                                                </div>

                                                <div style={{ marginInline: '15px', marginTop: '15px' }}>
                                                    {this.state.load}
                                                    <Notification
                                                        message={{
                                                            type: this.state.type,
                                                            info: this.state.info,
                                                        }}
                                                    />
                                                </div>
                                                <form action='post' onSubmit={this.onProcessCashout}>
                                                    <div class="modal-body">


                                                        <div class="form-row">
                                                            <div class="form-group col-md-12">
                                                                <label class="font-weight-semibold" for="oldPassword">Requested By:</label>
                                                                <input type="text" value={this.state.requesty} class="form-control" id="stateCity" readOnly />
                                                            </div>

                                                            <div class="form-group col-md-12">
                                                                <label class="font-weight-semibold" for="oldPassword">Amount:</label>
                                                                <input type="text" value={this.state.amounted} class="form-control" id="stateCity" readOnly />
                                                            </div>
                                                            <div class="form-group col-md-12">
                                                                <label class="font-weight-semibold" for="confirmPassword">Authorize with password:</label>
                                                                <input type="password" onChange={this.handlePassword} class="form-control" id="stateCity" placeholder="Confirm Password" />
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-default"
                                                            data-dismiss="modal">Close</button>
                                                        <button disabled={this.state.butt} type="submit" class="btn btn-success">Confirm Cashout</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="modal fade" id="exampleModalScrollableTwo" data-backdrop="static" data-keyboard="false">
                                        <div class="modal-dialog modal-dialog-scrollable">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalScrollableTitle"><b>Request Cashout</b><br /><b style={{ color: 'red' }}>[Action not reversible once approved]</b></h5>
                                                    <button type="button" class="close" data-dismiss="modal">
                                                        <i class="far fa-window-close"></i>
                                                    </button>
                                                </div>

                                                <div style={{marginInline:'15px', marginTop: '15px' }}>
                                                    {this.state.load}
                                                    <Notification
                                                        message={{
                                                            type: this.state.type,
                                                            info: this.state.info,
                                                        }}
                                                    />
                                                </div>
                                                <form action='post' onSubmit={this.onRequestCashout}>
                                                    <div class="modal-body">


                                                        <div class="form-row">
                                                            <div class="form-group col-md-12">
                                                                <label class="font-weight-semibold" for="oldPassword">Amount:</label>
                                                                <input type="text" onChange={this.handleAmounts} class="form-control" id="stateCity" placeholder="Amount" />
                                                            </div>
                                                            <div class="form-group col-md-12">
                                                                <label class="font-weight-semibold" for="newPassword">Confirm Amount:</label>
                                                                <input type="text" onChange={this.handleAmountNews} class="form-control" id="stateCity" placeholder="Confirm Amount" />
                                                            </div>
                                                            <div class="form-group col-md-12">
                                                                <label class="font-weight-semibold" for="confirmPassword">Authorize with password:</label>
                                                                <input type="password" onChange={this.handlePasswords} class="form-control" id="stateCity" placeholder="Confirm Password" />
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-default"
                                                            data-dismiss="modal">Close</button>
                                                        <button disabled={this.state.butt} type="submit" class="btn btn-success">Process Cashout</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>

            </>
        )
    }
}
export default Cashouts


