import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { DatePicker, Upload, message, Modal, Input, Select, Button, Form, Space } from 'antd';
import ajax from '../utils/ajax'
import functions from '../utils/functions'
import Header from './common/Header';
import Nav from './common/Nav';
import { Bars } from 'react-loader-spinner';
import NoDataFound from './common/NoDataFound';


class Events extends Component {

    state = {
        userId: functions.sessionGuard(),
        roleName: functions.roleGuard(),
        username: '',
        photo: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        active: '0',
        expired: '0',
        allMyEvents: false
    }

    componentDidMount() {
        this.getVendorEvents(0, 0);
    }

    getVendorEvents = async (active, expired) => {
        const server_response = await ajax.getVendorEvents(active, expired,this.state.roleName == "ADMIN" || this.state.roleName == "OPERATION" ? "0" : this.state.userId);
        const vendorEves = []
                    //console.log("yutysssc",this.state.roleName == "ADMIN" || this.state.roleName == "OPERATION" ? "0" : this.state.userId)

        if (server_response.status === "OK") {
            // console.log("yutysss", server_response)
            server_response.details.content.list.map((tren) => {
                vendorEves.push({
                    tour_id: tren.tour_id,
                    tour_name: tren.tour_name,
                    poster: tren.tour_image.file_path,
                    dated: tren.tour_start_date.short_date,
                    located: tren.tour_location,
                    priced: tren.price_list[0].price.total
                }
                )
            })

            this.setState({
                allMyEvents: vendorEves
            })

        } else {
            // console.log("yuty", server_response)
            this.setState({
                allMyEvents: []
            })
        }
    }

    handleEventStateChange = (value) => {

        if (value != null && value === '1') {
            this.setState({
                allMyEvents: []
            })
            this.getVendorEvents(0, 0)

        } else if (value != null && value === '2') {
            this.setState({
                allMyEvents: []
            })
            this.getVendorEvents(1, 0)
        } else if (value != null && value === '3') {
            this.setState({
                allMyEvents: []
            })
            this.getVendorEvents(0, 1)
        }
    }

    render() {
        const myevents = this.state.allMyEvents

        const options = [
            { value: '1', label: 'All Events' },
            { value: '2', label: 'Active Events' },
            { value: '3', label: 'Expired Events' }
        ]



        return (
            <>

                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <h5>All Recent Events / Tours / Adventures</h5>

                                                        <div>
                                                            <a href="/add-event" class="btn btn-primary btn-tone">
                                                                <i class="anticon anticon-mail"></i>
                                                                <span class="m-l-5">Add New Event / Adventure / Tour</span>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <hr />

                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <input type="text" class="form-control" placeholder="Search" />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <Select
                                                                size={'large'}
                                                                defaultValue="1"
                                                                // onChange={handleChange}
                                                                style={{ width: 250 }}
                                                                options={options}
                                                            />
                                                        </div>
                                                        <div class="col-md-5"></div>
                                                    </div>

                                                    <div class="m-t-30">


                                                        <div class="row" id="list-view">
                                                            <div class="col-md-12">
                                                                {/* <div class="card">
                                                                    <div class="card-body"> */}
                                                                <div class="table-responsive">
                                                                    {Array.isArray(myevents) && myevents.length === 0 ? (<NoDataFound term={{ tellme: this.state.serachTerm }} />) : (
                                                                        <>
                                                                            <table class="table table-hover table-bordered table table-md">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ fontWeight: 'bold' }}>Event / Tour / Adventure Name</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Location</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Date</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Status</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {Array.isArray(myevents) ?
                                                                                        (
                                                                                            myevents.map((item, key) =>

                                                                                                <tr key={key}>
                                                                                                    <td>
                                                                                                        <div class="media align-items-center">
                                                                                                            <div class="avatar avatar-image">
                                                                                                                <img src={item.poster} alt="listing-img" class="img-fluid" />                                                                                                </div>
                                                                                                            <div class="media-body m-l-15">
                                                                                                                <h6 class="mb-0">{item.tour_name}</h6>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td> <i class="flaticon-pin"></i>  {item.located}</td>
                                                                                                    <td>{item.dated}</td>
                                                                                                    <td><span class="badge badge-pill badge-success">Active</span>
                                                                                                    </td>
                                                                                                    <td class="text-right">
                                                                                                        <a href="#ß" class="btn btn-primary btn-tone">
                                                                                                            <i class="anticon anticon-mail"></i>
                                                                                                            <span class="m-l-5">Contact</span>
                                                                                                        </a>
                                                                                                    </td>
                                                                                                </tr>

                                                                                            )
                                                                                        ) :
                                                                                        (
                                                                                            <tr class="responsive-table">
                                                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                                    <Bars
                                                                                                        height="70"
                                                                                                        width="100"
                                                                                                        color="#F21313"
                                                                                                        ariaLabel="bars-loading"
                                                                                                        wrapperStyle={{}}
                                                                                                        wrapperClass=""
                                                                                                        visible={true}
                                                                                                    />
                                                                                                </div>
                                                                                            </tr>
                                                                                        )

                                                                                    }



                                                                                </tbody>
                                                                            </table>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {/* </div>
                                                                </div> */}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>

            </>
        )
    }
}
export default Events


