import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Table, Tooltip, Select } from 'antd';
import ajax from '../utils/ajax'
import functions from '../utils/functions'
import Header from './common/Header';
import Nav from './common/Nav';
import { Bars } from 'react-loader-spinner';
import NoDataFound from './common/NoDataFound';


class Sales extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: functions.loggedInUser(),
        roleName: functions.roleGuard(),
        photo: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        recentEvents: false
    }

    componentDidMount() {
        this.getMyTicketSales();
    }

    getMyTicketSales = async () => {
        const server_response = await ajax.myTicketSales(this.state.roleName === "ADMIN" || this.state.roleName === "OPERATION" ? "0" : this.state.userId);
        const mysales = []

        //console.log("jumbotronddd ", this.state.roleName)

        if (server_response.status === "OK") {

            server_response.details.content.list.map((sale) =>
                mysales.push({

                    id: sale.ticket_id,
                    title: sale.ticket_event,
                    price: sale.ticket_price[0].price.total_c === "0" ? "FREE" : sale.ticket_price[0].price.total_c,
                    category: sale.ticket_price[0].price_cat.price_cat_name,
                    fullname: sale.ticket_fullname,
                    phone: sale.ticket_phone,
                    email: sale.ticket_email,
                    status: sale.ticket_pay_status,
                    // validate:"sale",
                    createdAt: sale.created_at.short_date,
                    // createdBy: "sale"
                }),

            )

            this.setState({
                solds: mysales
            })

        } else {
            this.setState({
                solds: []
            })
        }
    }

    render() {

        const sold = this.state.solds

        const options = [
            { value: '1', label: 'Succeeded' },
            { value: '2', label: 'Failed' },
            { value: '3', label: 'All' }
        ]

        return (
            <>

                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <h5>Ticket Sales [ Includes FAILED and SUCCEEDED ]</h5>

                                                        <div>

                                                        </div>
                                                    </div>

                                                    <div class="row" style={{ marginTop: '10px' }}>
                                                        <div class="col-md-4">
                                                            <input type="text" class="form-control" placeholder="Search" />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <Select
                                                                size={'large'}
                                                                defaultValue="3"
                                                                // onChange={handleChange}
                                                                style={{ width: 250 }}
                                                                options={options}
                                                            />
                                                        </div>
                                                        <div class="col-md-5"></div>
                                                    </div>

                                                    <div class="m-t-30">


                                                        <div class="row" id="list-view">
                                                            <div class="col-md-12">
                                                                {/* <div class="card">
                                                                    <div class="card-body"> */}
                                                                <div class="table-responsive">
                                                                {Array.isArray(sold) && sold.length === 0 ? (<NoDataFound term={{ tellme: this.state.serachTerm }} />) : (
                                                                        <>

                                                                    <table class="table table-hover table-bordered table table-md">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ fontWeight: 'bold' }}>Event Name</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Price Ugx</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Category</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Client Name</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Client Phone</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Client Email</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Status</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Created At</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Actions</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>


                                                                            {Array.isArray(sold) ?
                                                                                (
                                                                                    sold.map((item, key) =>

                                                                                        <tr key={key}>
                                                                                            <td>{item.title}</td>
                                                                                            <td>{item.price}</td>
                                                                                            <td>{item.category}</td>
                                                                                            <td>{item.fullname}</td>
                                                                                            <td>{item.phone}</td>
                                                                                            <td>{item.email}</td>
                                                                                            <td>{(item.status === "3" ? <span className='badge badge-pill badge-success'>Success</span> : item.status === "2" ? <span class='badge badge-pill badge-danger'>Failed</span> : <span class='badge badge-pill badge-warning'>Pending</span>)}</td>
                                                                                            <td>{item.createdAt}</td>
                                                                                            <td class="text-right">

                                                                                                {(item.status === "3" ?


                                                                                                    <div className="text-nowrap">
                                                                                                        <Tooltip
                                                                                                            placement="leftTop"
                                                                                                            title="Validate Ticket"
                                                                                                            color="#454B1B"
                                                                                                            key="#f50"
                                                                                                            style={{ marginRight: "10px" }}
                                                                                                        >
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-success btn-tone"
                                                                                                                style={{ marginInlineEnd: "10px" }}
                                                                                                            // onClick={() => this.onClickReview(records.pendingOrderId, records.prescriptionimage,records.clientId,records.prescriptionId)}
                                                                                                            >
                                                                                                                <i className="fa fa-check" />
                                                                                                            </button>

                                                                                                        </Tooltip>

                                                                                                        <Tooltip
                                                                                                            placement="leftTop"
                                                                                                            title="Resend Ticket"
                                                                                                            color="#454B1B"
                                                                                                            key="#f50"
                                                                                                            style={{ marginRight: "10px" }}
                                                                                                        >
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-warning btn-tone"
                                                                                                                style={{ marginInlineEnd: "10px" }}
                                                                                                            // onClick={() => this.onClickReview(records.pendingOrderId, records.prescriptionimage,records.clientId,records.prescriptionId)}
                                                                                                            >
                                                                                                                <i className="fa fa-recycle" />
                                                                                                            </button>

                                                                                                        </Tooltip>

                                                                                                    </div>

                                                                                                    : <button
                                                                                                        type="button"
                                                                                                        className="btn btn-success btn-tone"
                                                                                                        style={{ marginInlineEnd: "10px" }}
                                                                                                    // onClick={() => this.onClickReview(records.pendingOrderId, records.prescriptionimage,records.clientId,records.prescriptionId)}
                                                                                                    >
                                                                                                        No Action
                                                                                                    </button>)}

                                                                                            </td>
                                                                                        </tr>

                                                                                    )
                                                                                ) :
                                                                                (
                                                                                    <tr class="responsive-table">
                                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                            <Bars
                                                                                                height="70"
                                                                                                width="100"
                                                                                                color="#F21313"
                                                                                                ariaLabel="bars-loading"
                                                                                                wrapperStyle={{}}
                                                                                                wrapperClass=""
                                                                                                visible={true}
                                                                                            />
                                                                                        </div>
                                                                                    </tr>
                                                                                )

                                                                            }




                                                                        </tbody>
                                                                    </table>
                                                                    </>
                                                                )}
                                                                </div>
                                                                {/* </div>
                                                                </div> */}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>

            </>
        )
    }
}
export default Sales