import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import functions from './utils/functions'
import Forgot from './pages/Auth/Forgot';
import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard';
import Reset from './pages/Auth/Reset';
import Events from './pages/Events';
import Users from './pages/Users';
import Statement from './pages/Statement';
import Sales from './pages/Sales';
import Settings from './pages/Settings';
import NewEvent from './pages/NewEvent';
import NewUser from './pages/NewUser';
import ErrorPage from './pages/ErrorPage';
import Verify from './pages/Verify';
import Cashouts from './pages/Cashouts';
import MobileMoneyList from './pages/MobileMoneyList';


class App extends Component {


  state = {
    screen: "Login",
    loggedIn: true,
    accessInfo: functions.permissionGuard()
  }

  componentDidMount() {
    this.checkLogin();
  }

  checkLogin() {
    if (!localStorage.getItem("funugAdmin@user")) {
      this.setState({
        loggedIn: false
      })
    } else {
      this.setState({
        loggedIn: true
      })
    }
  }

  render() {
    const roleList = functions.roleGuard()
    return (
      // <AuthContext>
      <Router basename="/" forceRefresh={true}>

        <Switch>
          {!this.state.loggedIn &&
            <Route exact path="/" component={Login} />
          }
          <Route exact path="/forgot" component={Forgot} />
          <Route exact path="/reset-password" component={Reset} />
          {/* <Route exact path="*" component={ErrorPage} /> */}


          {roleList === "ADMIN" &&
            <>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/add-user" component={NewUser} />
              <Route exact path="/add-event" component={NewEvent} />
              <Route exact path="/account-settings" component={Settings} />
              <Route exact path="/my-sales" component={Sales} />
              <Route exact path="/mobile-money-lists" component={MobileMoneyList} />
              <Route exact path="/my-statement" component={Statement} />
              <Route exact path="/list-users" component={Users} />
              <Route exact path="/my-events" component={Events} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/validate" component={Verify} />
              <Route exact path="/cashouts" component={Cashouts} />

            </>
          }


          {roleList === "OPERATION" &&
            <>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/add-user" component={NewUser} />
              <Route exact path="/add-event" component={NewEvent} />
              <Route exact path="/account-settings" component={Settings} />
              <Route exact path="/my-sales" component={Sales} />
              <Route exact path="/my-statement" component={Statement} />
              <Route exact path="/list-users" component={Users} />
              <Route exact path="/my-events" component={Events} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/validate" component={Verify} />
              <Route exact path="/cashouts" component={Cashouts} />

            </>
          }


          {roleList === "VENDOR" &&
            <>
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/add-event" component={NewEvent} />
              <Route exact path="/my-sales" component={Sales} />
              <Route exact path="/account-settings" component={Settings} />
              <Route exact path="/my-statement" component={Statement} />
              <Route exact path="/my-events" component={Events} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/validate" component={Verify} />
              <Route exact path="/cashouts" component={Cashouts} />

            </>
          }

        </Switch>
      </Router>
      // </AuthContext>
    )
  }
}
export default App