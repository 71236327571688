import React, { Component } from 'react'
import ajax from '../utils/ajax';
import functions from '../utils/functions';
import { Table } from 'antd';
// import 'antd/dist/antd.css';
import Nav from './common/Nav';
import Header from './common/Header';


class Dashboard extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: functions.loggedInUser(),
        photo: '',
        roleName: functions.roleGuard(),
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        recentEvents: false,
        counted: '00',
        commision: '00'
    }

    componentDidMount() {
        this.getRecentEvents();
        this.getMyBalance();
        this.getMyEvents()
        this.getMyCommission()
    }

    getRecentEvents = async () => {
        const server_response = await ajax.getRecentEvents(this.state.roleName == "ADMIN" || this.state.roleName == "OPERATION" ? "0" : this.state.userId);
        const recents = []

        //console.log("jumbo bf", server_response)

        if (server_response.status === "OK") {

            // console.log("jumbo ", server_response.details)

            server_response.details.content.list.map((recent) =>
                recents.push({
                    title: recent.tour_name,
                    location: recent.tour_location,
                    startDate: recent.start_date,
                    endDate: recent.tour_end_date,
                    price: recent.price_list[0].price.total + " UGX",
                    createdAt: recent.created_at.short_date,
                    createdBy: recent.created_by.username,
                }),
                //"start_date" => picker_format($data->tour_start_date),
            )

            this.setState({
                recentEvents: recents
            })

        } else {
            this.setState({
                recentEvents: []
            })
        }
    }

    getMyBalance = async () => {
        const server_response = await ajax.myBalance(this.state.roleName == "ADMIN" || this.state.roleName == "OPERATION" ? "0" : this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                balance: server_response.details.content.balance.total_c
            })

        } else {
            this.setState({
                balance: '00'
            })
        }
    }

    getMyCommission = async () => {
        const server_response = await ajax.myCommission(this.state.roleName == "ADMIN" || this.state.roleName == "OPERATION" ? "0" : this.state.userId);

        if (server_response.status === "OK") {

            this.setState({
                commision: server_response.details.content.balance.total_c
            })

        } else {
            this.setState({
                commision: '00'
            })
        }
    }

    getMyEvents = async () => {
        const server_response = await ajax.myTotalEvents(this.state.roleName == "ADMIN" || this.state.roleName == "OPERATION" ? "0" : this.state.userId);
        //console.log("jujusf", this.state.roleName)
        if (server_response.status === "OK") {

            this.setState({
                counted: server_response.details.content.total
            })

        } else {
            this.setState({
                counted: '00'
            })
        }
    }

    render() {


        const columns = [
            {
                title: 'Tour / Adventure Title',
                dataIndex: 'title',
            },
            {
                title: 'Location',
                dataIndex: 'location',
            },
            {
                title: 'Start Date',
                dataIndex: 'startDate'
            },
            {
                title: 'End Date',
                dataIndex: 'endDate'
            },
            {
                title: 'Price',
                dataIndex: 'price',
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt'
            },
            {
                title: 'Created By',
                dataIndex: 'createdBy'
            }
        ]

        const roleList = functions.roleGuard()

        //console.log("typerere ", roleList)


        return (


            <>


                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">

                                    {/* <div class="page-header no-gutters">
                                        <div class="d-md-flex align-items-md-center justify-content-between">
                                            <div class="media m-v-10 align-items-center">
                                                <div class="avatar avatar-image avatar-lg">
                                                    <img src="assets/images/avatars/thumb-3.jpg" alt="" />
                                                </div>
                                                <div class="media-body m-l-15">
                                                    <h4 class="m-b-0">Welcome back, {this.state.username}!</h4>
                                                    <span class="text-gray">{this.state.roleName}</span>
                                                </div>
                                            </div>
                                            <div class="d-md-flex align-items-center d-none">
                                                <div class="media align-items-center m-r-40 m-v-5">
                                                    <div class="font-size-27">
                                                        <i class="text-primary anticon anticon-profile"></i>
                                                    </div>
                                                    <div class="d-flex align-items-center m-l-10">
                                                        <h2 class="m-b-0 m-r-5">78</h2>
                                                        <span class="text-gray">Tasks</span>
                                                    </div>
                                                </div>
                                                <div class="media align-items-center m-r-40 m-v-5">
                                                    <div class="font-size-27">
                                                        <i class="text-success  anticon anticon-appstore"></i>
                                                    </div>
                                                    <div class="d-flex align-items-center m-l-10">
                                                        <h2 class="m-b-0 m-r-5">21</h2>
                                                        <span class="text-gray">Projects</span>
                                                    </div>
                                                </div>
                                                <div class="media align-items-center m-v-5">
                                                    <div class="font-size-27">
                                                        <i class="text-danger anticon anticon-team"></i>
                                                    </div>
                                                    <div class="d-flex align-items-center m-l-10">
                                                        <h2 class="m-b-0 m-r-5">39</h2>
                                                        <span class="text-gray">Members</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {roleList === "ADMIN" &&
                                        <>
                                            <div class="row">
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="media m-v-10 align-items-center">
                                                                    <div class="avatar avatar-image avatar-lg">
                                                                        <img src="assets/images/avatars/avatar-1.png" alt="" />
                                                                    </div>
                                                                    <div class="media-body m-l-15">
                                                                        <h5 class="m-b-0">Welcome, {this.state.username} !</h5>
                                                                        <span class="text-gray"><h5>{this.state.roleName}</h5></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 col-lg-3">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="m-b-0">Total Events</p>
                                                                    <h2 class="m-b-0">
                                                                        <span>{this.state.counted}</span>
                                                                    </h2>
                                                                </div>
                                                                <div class="avatar avatar-icon avatar-lg avatar-red">
                                                                    <i class="fas fa-boxes"></i>                                                        </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 col-lg-3">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="m-b-0">Commission</p>
                                                                    <h2 class="m-b-0">
                                                                        <span>{this.state.commision} Ugx</span>
                                                                    </h2>
                                                                </div>
                                                                <div class="avatar avatar-icon avatar-lg avatar-purple">
                                                                    <i class="fas fa-pound-sign"></i>                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 col-lg-3">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="m-b-0">Account Balance</p>
                                                                    <h2 class="m-b-0">
                                                                        <span>{this.state.balance} Ugx</span>
                                                                    </h2>
                                                                </div>
                                                                <div class="avatar avatar-icon avatar-lg avatar-gold">
                                                                    <i class="fas fa-dollar-sign"></i>                                                        </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    }

                                    {roleList === "VENDOR" &&
                                        <>

                                            <div class="row">
                                                <div class="col-md-5 col-lg-5">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="media m-v-10 align-items-center">
                                                                    <div class="avatar avatar-image avatar-lg">
                                                                        <img src="assets/images/avatars/avatar-1.png" alt="" />
                                                                    </div>
                                                                    <div class="media-body m-l-15">
                                                                        <h5 class="m-b-0">Welcome, {this.state.username} !</h5>
                                                                        <span class="text-gray"><h5>{this.state.roleName}</h5></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-6 col-lg-3">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="m-b-0">Total Events / Tours</p>
                                                                    <h2 class="m-b-0">
                                                                        <span>{this.state.counted}</span>
                                                                    </h2>
                                                                </div>
                                                                <div class="avatar avatar-icon avatar-lg avatar-red">
                                                                    <i class="fas fa-boxes"></i>                                                        </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 col-lg-4">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="m-b-0">Account Balance</p>
                                                                    <h2 class="m-b-0">
                                                                        <span>{this.state.balance} Ugx</span>
                                                                    </h2>
                                                                </div>
                                                                <div class="avatar avatar-icon avatar-lg avatar-gold">
                                                                    <i class="fas fa-dollar-sign"></i>                                                        </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </>
                                    }

                                    {roleList === "OPERATION" &&
                                        <>
                                            <div class="row">
                                                <div class="col-md-3 col-lg-3">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div class="media m-v-10 align-items-center">
                                                                    <div class="avatar avatar-image avatar-lg">
                                                                        <img src="assets/images/avatars/avatar-1.png" alt="" />
                                                                    </div>
                                                                    <div class="media-body m-l-15">
                                                                        <h5 class="m-b-0">Welcome, {this.state.username} !</h5>
                                                                        <span class="text-gray"><h5>{this.state.roleName}</h5></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 col-lg-3">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="m-b-0">Total Events</p>
                                                                    <h2 class="m-b-0">
                                                                        <span>{this.state.counted}</span>
                                                                    </h2>
                                                                </div>
                                                                <div class="avatar avatar-icon avatar-lg avatar-red">
                                                                    <i class="fas fa-boxes"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 col-lg-3">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="m-b-0">Commission</p>
                                                                    <h2 class="m-b-0">
                                                                        <span>{this.state.commision} Ugx</span>
                                                                    </h2>
                                                                </div>
                                                                <div class="avatar avatar-icon avatar-lg avatar-purple">
                                                                    <i class="fas fa-pound-sign"></i>                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="col-md-3 col-lg-3">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            <div class="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <p class="m-b-0">Account Balance</p>
                                                                    <h2 class="m-b-0">
                                                                        <span>{this.state.balance} Ugx</span>
                                                                    </h2>
                                                                </div>
                                                                <div class="avatar avatar-icon avatar-lg avatar-gold">
                                                                    <i class="fas fa-dollar-sign"></i>                                                        </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </>
                                    }

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <h5>My Recent Events / Tours / Adventures</h5>
                                                        <div>
                                                            <a href="/my-events" class="btn btn-sm btn-default">View All</a>
                                                        </div>
                                                    </div>
                                                    <div class="m-t-30">
                                                        <Table
                                                            columns={columns}
                                                            bordered
                                                            dataSource={this.state.recentEvents}
                                                            pagination={{ pageSizeOptions: ['10'], showTitle: false, hideOnSinglePage: false }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>


            </>


        )
    }
}
export default Dashboard
