import React, { useState,useEffect } from "react";
// import { Container } from "react-bootstrap";
import { DatePicker, Upload, message, Modal, Input, Select, Button, Form, Space } from 'antd';
import ajax from "../../utils/ajax";
import functions from "../../utils/functions";


function AddTicketTypes(props) {
    const [inputList, setinputList] = useState([{ category: '', price: '' }]);
    const [ticketCats, setTicketCats] = useState(false);
    const { Option } = Select;
    const [userId, setUserId] = useState(functions.sessionGuard())


    const handleinputchange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setinputList(list);

    }

    const handleTicketCategory = (e, index) => {
        props.parentCallback(inputList)

        if (e != null) {
            const list = [...inputList];
            list[index]["category"] = e;
            setinputList(list);
        } else {
            //
        }
    }

    const handleremove = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setinputList(list);
    }

    const handleaddclick = () => {
        setinputList([...inputList, { category: '', price: '' }]);
    }


    const getTicketCategories = async (idd) => {
        const server_response = await ajax.getPriceCategories(idd);
        const catOptions = []

        if (server_response.status === "OK") {

            server_response.details.content.list.map((cats) =>
                catOptions.push(<Option value={cats.price_cat_id}>{cats.price_cat_name}</Option>),

            )

            setTicketCats(catOptions)

        } else {
            setTicketCats(false)
        }
    }

    useEffect(() => {
        if (userId) {
            getTicketCategories(userId)
        }
    }, [userId])

    return (
        < div className="content">
            <div className="row">
                <div className="col-sm-12">
                    {
                        inputList.map((x, i) => {
                            return (
                                <div className="row">
                                    <div class="form-group col-md-5 col-sm-6">
                                        <label >Ticket Category</label>
                                        {/* <input type="text" name="firstName" class="form-control" placeholder="Enter First Name" onChange={e => handleinputchange(e, i)} /> */}

                                        <Select
                                            size='large'
                                            showSearch
                                            name='category'
                                            style={{
                                                width: '100%',
                                            }}
                                            onChange={e => handleTicketCategory(e, i)}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                        >
                                            {ticketCats}

                                        </Select>

                                    </div>
                                    <div class="form-group col-md-5 col-sm-6">
                                        <label >Price</label>
                                        {/* <input type="text" name="lastName" class="form-control" placeholder="Enter Last Name" onChange={e => handleinputchange(e, i)} /> */}
                                        <Input size="large" name="price" style={{ width: '100%' }} placeholder="price" onChange={e => handleinputchange(e, i)} />

                                    </div>
                                    <div class="form-group col-md-2 col-sm-6 mt-4">
                                        {
                                            inputList.length !== 1 &&
                                            <button className="btn btn-danger mx-1" onClick={() => handleremove(i)}>x</button>
                                        }
                                        {inputList.length - 1 === i &&
                                            <button style={{ marginInlineStart: '10px' }} className="btn btn-md btn-success" onClick={handleaddclick}>Add</button>
                                        }
                                    </div>
                                </div>
                            );
                        })}


                </div>
            </div>
        </div>
    );
}
export default AddTicketTypes;