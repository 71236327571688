import React, { Component } from 'react'
// import { Modal, Select, Table } from 'antd'
// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// import Notification from ' ../../common/Notification'
// import Loader from '../../common/Loader'
import ajax from '../../utils/ajax'
import functions from '../../utils/functions'
import Loader from '../common/Loader'
import Notification from '../common/Notification'


class Forgot extends Component {

    state = {
        username: '',
        password: '',
        type: '',
        info: '',
        load: '',
    }

    onPressLogin = async (event) => {

        event.preventDefault()
        const { username, password } = this.state

        if (username.length > 0 && password.length > 0) {

            this.setState({
                type: '',
                info: '',
                load: <Loader />
            })
            //fetch login from the API
            const server_response = await ajax.loginUser(username, password)

            if (server_response.status === 'OK') {
                localStorage.setItem(
                    'funugAdmin@user',
                    server_response.details.content.access_token,
                )

                // decode the token to access some variables for use
                const access_token = localStorage.getItem('funugAdmin@user')
                const decorded_token = functions.parseJwt(access_token)

                const role_id = decorded_token['data']['role_id']
                const is_secure = decorded_token['data']['is_secure']

                if (role_id === '2' || role_id === '3' || role_id === '4') {
                    this.setState({
                        type: 'alert alert-danger',
                        info: 'You are not authorised to use this system',
                        load: '',
                    })
                } else if (is_secure === '0') {
                    this.props.history.push('/reset-password')
                    window.location.reload()
                } else if (server_response.status === 'OK') {

                    this.setState({
                        type: 'alert alert-success',
                        info: 'Credentials Accepted, Redirecting you . . ',
                        load: '',
                    })

                    this.props.history.push('/')
                    window.location.reload()
                }
            } else {
                this.setState({
                    type: 'alert alert-danger',
                    info: server_response.details.message,
                    load: '',
                })
            }
        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'Email Address is Required',
                load: '',
            })
        }


    }

    onChangeUsername = (event) => {
        this.setState({ username: event.target.value })
    }

    onChangePassword = (event) => {
        this.setState({ password: event.target.value })
    }

    render() {
        return (

            <body>
                <div class="app">
                    <div class="container-fluid p-h-0 p-v-20 bg full-height d-flex" style={{ backgroundImage: "url(" + 'assets/images/others/login-3.png' + ")" }}>
                        <div class="d-flex flex-column justify-content-between w-100">
                            <div class="container d-flex h-100">
                                <div class="row align-items-center w-100">
                                    <div class="col-md-7 col-lg-5 m-h-auto">
                                        <div class="card shadow-lg">
                                            <div class="card-body">
                                                {this.state.load}
                                                <Notification
                                                    message={{
                                                        type: this.state.type,
                                                        info: this.state.info,
                                                    }}
                                                />
                                                <div class="d-flex align-items-center justify-content-between m-b-30">
                                                <img class="img-fluid" alt="" src="assets/images/logo/black-logo.png" style={{ height: '60px', width: '110px', marginTop: '10px' }} />
                                                    <h3>Forgot</h3>
                                                </div>
                                                <form method="post" onSubmit={this.onPressLogin}>
                                                    <div class="form-group">
                                                        <label class="font-weight-semibold" for="userName">Email:</label>
                                                        <div class="input-affix">
                                                            <i class="prefix-icon fas fa-envelope"></i>
                                                            <input type="text" name="email" class="form-control" placeholder="Email Address" />
                                                        </div>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="d-flex align-items-center justify-content-between">
                                                            <button class="btn btn-primary">Send Reset Email</button>
                                                        </div>

                                                        <div style={{marginTop:'20px'}}>
                                                            <span>Remember password? <a href="/">Login here</a></span>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-none d-md-flex p-h-40 justify-content-between">
                                <span class="">© 2023 Funug</span>
                            </div>
                        </div>
                    </div>
                </div>

            </body>

        )
    }
}

export default Forgot