import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Table, Tooltip, Select } from 'antd';
import ajax from '../utils/ajax'
import functions from '../utils/functions'
import Header from './common/Header';
import Nav from './common/Nav';
import { Bars } from 'react-loader-spinner';
import { SpinnerCircularSplit } from 'spinners-react';
import NoDataFound from './common/NoDataFound';


class MobileMoneyList extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: functions.loggedInUser(),
        roleName: functions.roleGuard(),
        photo: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        recentEvents: false,
        tranStated: '',
        currentPage: 1,
        metaData: false,
        solds: false,
        processing: false
    }

    componentDidMount() {
        this.getMyTicketSales();
    }

    // componentDidUpdate() {
    //     const {tranStated} = this.state
    //     if (tranStated != '') {
    //         this.getMyTicketSales()

    //         this.setState({
    //             tranStated: ""
    //         })
    //     }
    // }

    onClickNext = () => {
        const { currentPage, metaData } = this.state
        if (currentPage < metaData.total_pages) {
            this.setState({
                processing: true,
                currentPage: currentPage + 1
            }, () => this.loadMore(currentPage + 1))

        }
    }

    loadMore = async (currentPage) => {
        const { solds } = this.state
        const mysales = []

        const server_response = await ajax.mobileMoneyLists(this.state.roleName === "ADMIN" || this.state.roleName === "OPERATION" ? "0" : this.state.userId, currentPage, this.state.tranStated);
        // alert(this.state.tranStated)
        if (server_response.status === "OK") {

            //console.log("jumbotronddd 02", server_response.details.content.list)

            server_response.details.content.list.map((sale) =>
                mysales.push({

                    id: sale.request_id,
                    title: sale.event,
                    type: sale.type,
                    price: sale.amount.amount_c,
                    fullname: sale.ticket_fullname,
                    phone: sale.source_mobile,
                    status: sale.status.id,
                    createdAt: sale.created_at.long_date,
                    updatedAt: sale.updated_at.long_date
                }),

            )

            const added = Array.from(mysales)

            this.setState({
                solds: solds.concat(added),
                metaData: server_response.details.content.meta,
                processing: false
            })


        } else {
            // console.log("jumbotronddd", server_response.details.content.list)
        }
    }

    getMyTicketSales = async () => {

        const server_response = await ajax.mobileMoneyLists(this.state.roleName === "ADMIN" || this.state.roleName === "OPERATION" ? "0" : this.state.userId, this.state.currentPage, this.state.tranStated);
        const mysales = []

        // alert(this.state.tranStated)

        console.log("jumbotronddd", this.state.tranStated)

        if (server_response.status === "OK") {

            // console.log("jumbotronddd 01", server_response.details.content.list)

            server_response.details.content.list.map((sale) =>
                mysales.push({

                    id: sale.request_id,
                    title: sale.event,
                    type: sale.type,
                    price: sale.amount.amount_c,
                    fullname: sale.ticket_fullname,
                    phone: sale.source_mobile,
                    status: sale.status.id,
                    createdAt: sale.created_at.long_date,
                    updatedAt: sale.updated_at.long_date
                }),

            )

            this.setState({
                solds: mysales,
                metaData: server_response.details.content.meta
            })

        } else {
            // console.log("jumbotronddd", server_response)
            this.setState({
                solds: [],
                metaData: false
            })
        }
    }

    handleStatusChange = (value) => {

        //  alert(value)
        if (value != null) {

            this.setState({
                tranStated: value
            })


            setTimeout(
                () => {
                    this.getMyTicketSales()
                },
                250)

        } else {
            this.setState({
                tranStated: ""
            })
        }
    }

    render() {

        const sold = this.state.solds

        const options = [
            { value: '3', label: 'Succeeded' },
            { value: '2', label: 'Failed' },
            { value: '1', label: 'Pending' }
        ]

        return (
            <>

                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <h5>Mobile Money Transactions</h5>

                                                        <div>

                                                        </div>
                                                    </div>

                                                    <div class="row" style={{ marginTop: '10px' }}>
                                                        <div class="col-md-4">
                                                            <input type="text" class="form-control" placeholder="Search" />
                                                        </div>
                                                        <div class="col-md-3">
                                                            <Select
                                                                size={'large'}
                                                                defaultValue="3"
                                                                onChange={this.handleStatusChange}
                                                                style={{ width: 250 }}
                                                                options={options}
                                                            />
                                                        </div>
                                                        <div class="col-md-5"></div>
                                                    </div>

                                                    <div class="m-t-30">


                                                        <div class="row" id="list-view">
                                                            <div class="col-md-12">
                                                                {/* <div class="card">
                                                                    <div class="card-body"> */}
                                                                <div class="table-responsive">
                                                                    {Array.isArray(sold) && sold.length === 0 ? (<NoDataFound term={{ tellme: this.state.serachTerm }} />) : (
                                                                        <>

                                                                            <table class="table table-hover table-bordered table table-md nowrap">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ fontWeight: 'bold' }}>Id</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Event Name</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Type</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Client Phone</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Amount Ugx</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Status</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Created At</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Updated At</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Actions</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>


                                                                                    {Array.isArray(sold) ?
                                                                                        (
                                                                                            sold.map((item, key) =>

                                                                                                <tr key={key}>
                                                                                                    <td>{item.id}</td>
                                                                                                    <td>{item.title}</td>
                                                                                                    <td>{item.type}</td>
                                                                                                    <td>{item.phone}</td>
                                                                                                    <td>{item.price}</td>
                                                                                                    <td>{(item.status === "3" ? <span className='badge badge-pill badge-success'>Success</span> : item.status === "2" ? <span class='badge badge-pill badge-danger'>Failed</span> : <span class='badge badge-pill badge-warning'>Pending</span>)}</td>
                                                                                                    <td>{item.createdAt}</td>
                                                                                                    <td>{item.updatedAt}</td>
                                                                                                    <td class="text-right">

                                                                                                        {(item.status === "1" ?


                                                                                                            <div className="text-nowrap">

                                                                                                                <Tooltip
                                                                                                                    placement="leftTop"
                                                                                                                    title="Refresh Trasaction"
                                                                                                                    color="#454B1B"
                                                                                                                    key="#f50"
                                                                                                                    style={{ marginRight: "10px" }}
                                                                                                                >
                                                                                                                    <button
                                                                                                                        type="button"
                                                                                                                        className="btn btn-warning btn-tone"
                                                                                                                        style={{ marginInlineEnd: "10px" }}
                                                                                                                    // onClick={() => this.onClickReview(records.pendingOrderId, records.prescriptionimage,records.clientId,records.prescriptionId)}
                                                                                                                    >
                                                                                                                        <i className="fa fa-recycle" />
                                                                                                                    </button>

                                                                                                                </Tooltip>

                                                                                                            </div>

                                                                                                            : <button
                                                                                                                type="button"
                                                                                                                className="btn btn-success btn-tone"
                                                                                                                style={{ marginInlineEnd: "10px" }}
                                                                                                            // onClick={() => this.onClickReview(records.pendingOrderId, records.prescriptionimage,records.clientId,records.prescriptionId)}
                                                                                                            >
                                                                                                                No Action
                                                                                                            </button>)}

                                                                                                    </td>
                                                                                                </tr>

                                                                                            )
                                                                                        ) :
                                                                                        (
                                                                                            <tr class="responsive-table">
                                                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                                    <Bars
                                                                                                        height="70"
                                                                                                        width="100"
                                                                                                        color="#F21313"
                                                                                                        ariaLabel="bars-loading"
                                                                                                        visible={true}
                                                                                                    />
                                                                                                </div>
                                                                                            </tr>
                                                                                        )

                                                                                    }




                                                                                </tbody>
                                                                            </table>
                                                                            {this.state.metaData.total_pages != this.state.currentPage &&

                                                                                <p class="text-center">

                                                                                    {this.state.processing ? (
                                                                                        <button type="button" className="btn btn-success btn-warning">
                                                                                            <SpinnerCircularSplit thickness={200} enabled={this.state.processing} color='#FFFFFF' height='20' />
                                                                                        </button>
                                                                                    ) : (
                                                                                        <button onClick={this.onClickNext} type="button" className="btn btn-success btn-info">
                                                                                            Load more
                                                                                        </button>
                                                                                    )}
                                                                                </p>
                                                                            }
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {/* </div>
                                                                </div> */}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>

            </>
        )
    }
}
export default MobileMoneyList