import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { DatePicker, Upload, message, Modal, Input, Select, Button, Form, Space } from 'antd';
import ajax from '../utils/ajax'
import functions from '../utils/functions'
import Header from './common/Header';
import Nav from './common/Nav';
import { Bars } from 'react-loader-spinner';


class Users extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: '',
        photo: '',
        roleName: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        active: '0',
        expired: '0',
        allSystemUsers: false
    }

    componentDidMount() {
        this.getSystemUsers();
    }

    getSystemUsers = async () => {
        const server_response = await ajax.listUsers(this.state.userId);
        const userList = []

        if (server_response.status === "OK") {
            console.log("yutysss", server_response)
            server_response.details.content.list.map((user) => {
                userList.push({
                    user_id: user.user_id,
                    username: user.username,
                    fullname: user.first_name + " " + user.last_name,
                    role: user.user_role.role_name,
                    status: user.status,
                    created_by: user.created_by.first_name + " " + user.created_by.last_name,
                    created_at: user.created_at.long_date
                }
                )
            })

            this.setState({
                allSystemUsers: userList
            })

        } else {
            console.log("yuty", server_response)
            this.setState({
                allSystemUsers: []
            })
        }
    }

    handleEventStateChange = (value) => {

        if (value != null && value === '1') {
            this.setState({
                allSystemUsers: []
            })
            this.getVendorEvents(0, 0)

        } else if (value != null && value === '2') {
            this.setState({
                allSystemUsers: []
            })
            this.getVendorEvents(1, 0)
        } else if (value != null && value === '3') {
            this.setState({
                allSystemUsers: []
            })
            this.getVendorEvents(0, 1)
        }
    }

    render() {
        const myUsers = this.state.allSystemUsers

        const options = [
            { value: '1', label: 'All Events' },
            { value: '2', label: 'Active Events' },
            { value: '3', label: 'Expired Events' }
        ]



        return (
            <>

                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <h5>All System users</h5>

                                                        <div>
                                                            <a href="/add-user" class="btn btn-primary btn-tone">
                                                                <i class="anticon anticon-mail"></i>
                                                                <span class="m-l-5">Add New User</span>
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <input type="text" class="form-control" placeholder="Search" />
                                                        </div>
                                                        <div class="col-md-3">

                                                        </div>
                                                        <div class="col-md-5"></div>
                                                    </div>

                                                    <div class="m-t-30">


                                                        <div class="row" id="list-view">
                                                            <div class="col-md-12">
                                                                {/* <div class="card">
                                                                    <div class="card-body"> */}
                                                                <div class="table-responsive">
                                                                    <table class="table table-hover table-bordered table table-md">
                                                                        <thead>
                                                                            <tr>
                                                                                <th style={{ fontWeight: 'bold' }}>Username</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Fullname</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Role</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Status</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Created By</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Created At</th>
                                                                                <th style={{ fontWeight: 'bold' }}>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>


                                                                            {Array.isArray(myUsers) ?
                                                                                (
                                                                                    myUsers.map((item, key) =>

                                                                                        <tr key={key}>
                                                                                            <td>{item.username}</td>
                                                                                            <td>{item.fullname}</td>
                                                                                            <td>{item.role}</td>
                                                                                            <td>{item.status}</td>
                                                                                            <td>{item.created_by}</td>
                                                                                            <td>{item.created_at}</td>
                                                                                            <td>
                                                                                                <button style={{marginInlineEnd:'5px'}} type="button" class="btn btn-danger btn-tone">
                                                                                                    Reset
                                                                                                </button>

                                                                                                <button type="button" class="btn btn-success btn-tone">
                                                                                                    View
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>

                                                                                    )
                                                                                ) :
                                                                                (
                                                                                    <tr class="responsive-table">
                                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                            <Bars
                                                                                                height="70"
                                                                                                width="100"
                                                                                                color="#F21313"
                                                                                                ariaLabel="bars-loading"
                                                                                                wrapperStyle={{}}
                                                                                                wrapperClass=""
                                                                                                visible={true}
                                                                                            />
                                                                                        </div>
                                                                                    </tr>
                                                                                )

                                                                            }


                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {/* </div>
                                                                </div> */}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>

            </>
        )
    }
}
export default Users


