import React, { Component } from 'react'
import ajax from '../utils/ajax';
import functions from '../utils/functions';
import { Table } from 'antd';
import 'antd/dist/antd.css';
import Nav from './common/Nav';
import Header from './common/Header';


class Settings extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: '',
        photo: '',
        roleName: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: ''
    }

    componentDidMount() {
        this.getUserProfile();
    }

    getUserProfile = async () => {
        const server_response = await ajax.userInfo(this.state.userId);

        if (server_response.status === "OK") {

            //console.log(" sss sss ", server_response.details.content)

            this.setState({
                username: server_response.details.content.username,
                photo: server_response.details.content.profile_photo.file_path,
                roleName: server_response.details.content.user_role.code,
                first_name: server_response.details.content.first_name,
                last_name: server_response.details.content.last_name,
                phone: server_response.details.content.phone_number,
                email: server_response.details.content.email
            })

        } else {
            // this.setState({
            //     livetEvents: "404"
            // })
        }
    }


    render() {
        return (


            <>


                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">
                                    <div class="page-header no-gutters has-tab">
                                        <h2 class="font-weight-normal">Setting</h2>
                                        <ul class="nav nav-tabs" >
                                            <li class="nav-item">
                                                <a class="nav-link active" data-toggle="tab" href="#tab-account">Account</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" data-toggle="tab" href="#tab-network">Payment Preferences</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="container">
                                        <div class="tab-content m-t-15">
                                            <div class="tab-pane fade show active" id="tab-account" >
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h4 class="card-title">Basic Infomation</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="media align-items-center">
                                                            <div class="avatar avatar-image  m-h-10 m-r-15" style={{ height: '80px', width: '80px' }}>
                                                                <img src="assets/images/avatars/avatar-1.png" alt="" />
                                                            </div>
                                                            <div class="m-l-20 m-r-20">
                                                                <h5 class="m-b-5 font-size-18">Change Avatar</h5>
                                                                <p class="opacity-07 font-size-13 m-b-0">
                                                                    Recommended Dimensions: <br />
                                                                    120x120 Max fil size: 5MB
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <button class="btn btn-tone btn-primary">Upload</button>
                                                            </div>
                                                        </div>
                                                        <hr class="m-v-25" />
                                                        <form>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-3">
                                                                    <label class="font-weight-semibold" for="userName">Username:</label>
                                                                    <input type="text" class="form-control" id="userName" value={this.state.username} readOnly />
                                                                </div>
                                                                <div class="form-group col-md-3">
                                                                    <label class="font-weight-semibold" for="userName">First Name:</label>
                                                                    <input type="text" class="form-control" id="userName" value={this.state.first_name} />
                                                                </div>
                                                                <div class="form-group col-md-3">
                                                                    <label class="font-weight-semibold" for="email">Last Name:</label>
                                                                    <input type="tex" class="form-control" id="email" placeholder="email" value={this.state.last_name} />
                                                                </div>

                                                                <div class="form-group col-md-3">
                                                                    <label class="font-weight-semibold" for="email">Email:</label>
                                                                    <input type="text" class="form-control" id="email" placeholder="email" value={this.state.email} />
                                                                </div>
                                                            </div>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="phoneNumber">Phone Number:</label>
                                                                    <input type="text" class="form-control" id="phoneNumber" value={this.state.phone} />
                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="dob">Continent:</label>
                                                                    <input type="text" class="form-control" id="dob" value={'Africa'} readOnly />
                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="language">Country</label>
                                                                    <input type="text" class="form-control" id="dob" value={'Uganda'} readOnly />

                                                                </div>
                                                            </div>

                                                            <div class="form-row">
                                                                <div class="form-group col-md-3">
                                                                    <button class="btn btn-primary">Update Details</button>
                                                                </div>
                                                                <div class="form-group col-md-3"></div>
                                                                <div class="form-group col-md-3"></div>
                                                                <div class="form-group col-md-3"></div>
                                                            </div>

                                                        </form>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h4 class="card-title">Change Password</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <form>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-3">
                                                                    <label class="font-weight-semibold" for="oldPassword">Old Password:</label>
                                                                    <input type="password" class="form-control" id="oldPassword" placeholder="Old Password" />
                                                                </div>
                                                                <div class="form-group col-md-3">
                                                                    <label class="font-weight-semibold" for="newPassword">New Password:</label>
                                                                    <input type="password" class="form-control" id="newPassword" placeholder="New Password" />
                                                                </div>
                                                                <div class="form-group col-md-3">
                                                                    <label class="font-weight-semibold" for="confirmPassword">Confirm Password:</label>
                                                                    <input type="password" class="form-control" id="confirmPassword" placeholder="Confirm Password" />
                                                                </div>
                                                                <div class="form-group col-md-3">
                                                                    <button class="btn btn-primary m-t-30">Change</button>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="tab-pane fade" id="tab-network" >
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h4 class="card-title">Payment Preferences</h4>
                                                    </div>
                                                    <div class="card-body">
                                                        <form>
                                                            <div class="form-row">
                                                                <div class="form-group col-md-12">
                                                                    <label class="font-weight-semibold" for="fullAddress">Full Address:</label>
                                                                    <input type="text" class="form-control" id="fullAddress" placeholder="Full Address" readOnly/>
                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity">Mobile Money Number:</label>
                                                                    <input type="text" class="form-control" id="stateCity" value={this.state.phone} />
                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="stateCity">Payment Mode:</label>
                                                                    <select id="language-2" class="form-control">
                                                                        <option>Bank</option>
                                                                        <option selected>Mobile Money</option>
                                                                    </select>
                                                                </div>
                                                                <div class="form-group col-md-4">
                                                                    <label class="font-weight-semibold" for="language">Disbursement Preference</label>
                                                                    <select id="language-2" class="form-control">
                                                                        <option>Automatic</option>
                                                                        <option>Manual</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>


            </>


        )
    }
}
export default Settings
