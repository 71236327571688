import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { DatePicker, Upload, message, Modal, Input, Select, Button, Form, Space } from 'antd';
import ajax from '../utils/ajax'
import functions from '../utils/functions'
import Header from './common/Header';
import Nav from './common/Nav';
import { Bars } from 'react-loader-spinner';
import NoDataFound from './common/NoDataFound';
import Loader from './common/Loader';
import Notification from './common/Notification';


class Statement extends Component {

    state = {
        userId: functions.sessionGuard(),
        roleName: functions.roleGuard(),
        username: functions.loggedInUser(),
        photo: '',
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        address: '',
        statement: false,
        amount: '',
        amountNew: '',
        password: '',
        butt:false
    }

    requestcashout

    componentDidMount() {
        this.getMyTicketSales();
    }

    getMyTicketSales = async () => {
        const server_response = await ajax.myStatement(this.state.roleName == "ADMIN" || this.state.roleName == "OPERATION" ? "0" : this.state.userId);
        const mystatement = []

        //console.log("jumbotron ",server_response)

        if (server_response.status === "OK") {

            server_response.details.content.list.map((statem) =>
                mystatement.push({

                    manager: statem.username,
                    title: statem.tour_name,
                    category: statem.ticket_category,
                    fullname: statem.fullname,
                    phone: statem.phone_number,
                    debit: statem.debit.amount_c,
                    credit: statem.credit.amount_c,
                    balance: statem.balance.amount_c,
                    createdAt: statem.created_at.short_date,
                }),

            )

            this.setState({
                statement: mystatement
            })

        } else {
            this.setState({
                statement: []
            })
        }
    }

    handleAmount = (value) => {

        this.setState({
            amount: value.target.value
        })
    }

    handleAmountNew = (value) => {

        this.setState({
            amountNew: value.target.value
        })
    }

    handlePassword = (value) => {

        this.setState({
            password: value.target.value
        })
    }

    onRequestCashout = async (event) => {
        event.preventDefault()

        const { amount, amountNew, password } = this.state

        if (amount.length > 0 &&
            amountNew.length > 0 &&
            password.length > 0) {

            if (amount !== amountNew) {
                this.setState({
                    type: 'alert alert-danger',
                    info: "Amounts Don't match",
                    load: '',
                })
            } else {

                this.setState({
                    type: '',
                    info: '',
                    butt:true,
                    load: <Loader />
                })

                const server_response = await ajax.requestcashout(amount,this.state.username, password,this.state.userId)

                if (server_response.status === "OK") {

                    //console.log("yutysssss", server_response)

                    this.setState({
                        type: 'alert alert-success',
                        info: server_response.details.message,
                        load: ''
                    })

                    setTimeout(function () {
                        window.location.reload();
                    }, 5000);

                } else {

                    this.setState({
                        type: 'alert alert-danger',
                        info: server_response.details.message,
                        load: '',
                        butt:false
                    })

                }

            }

        } else {
            this.setState({
                type: 'alert alert-danger',
                info: 'All fields are required ',
                butt:false
            })
        }

    }

    render() {

        const accountStatement = this.state.statement

        return (
            <>

                <body>
                    <div class="app">
                        <div class="layout">

                            <Header />

                            <Nav />

                            {/* <!-- Page Container START --> */}
                            <div class="page-container">

                                {/* <!-- Content Wrapper START --> */}
                                <div class="main-content">

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <h5>Account Statement</h5>

                                                        <div>

                                                            <button type="button" class="btn btn-primary" data-toggle="modal"
                                                                data-target="#exampleModalScrollable">
                                                                Request Cashout
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <hr />


                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <input type="text" class="form-control" placeholder="Search" />
                                                        </div>
                                                        <div class="col-md-3">

                                                        </div>
                                                        <div class="col-md-5"></div>
                                                    </div>

                                                    <div class="m-t-30">


                                                        <div class="row" id="list-view">
                                                            <div class="col-md-12">
                                                                {/* <div class="card">
                                                                    <div class="card-body"> */}
                                                                <div class="table-responsive">

                                                                    {Array.isArray(accountStatement) && accountStatement.length === 0 ? (<NoDataFound term={{ tellme: this.state.serachTerm }} />) : (
                                                                        <>

                                                                            <table class="table table-hover table-bordered table table-md">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ fontWeight: 'bold' }}>Event Manager</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Event Name</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Category</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Client Name</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Client Phone</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Credit</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Debit</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Balance</th>
                                                                                        <th style={{ fontWeight: 'bold' }}>Created At</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>


                                                                                    {Array.isArray(accountStatement) ?
                                                                                        (
                                                                                            accountStatement.map((item, key) =>

                                                                                                <tr key={key}>
                                                                                                    <td>{item.manager}</td>
                                                                                                    <td>{item.title}</td>
                                                                                                    <td>{item.category}</td>
                                                                                                    <td>{item.fullname}</td>
                                                                                                    <td>{item.phone}</td>
                                                                                                    <td>{item.credit}</td>
                                                                                                    <td>{item.debit}</td>
                                                                                                    <td>{item.balance}</td>
                                                                                                    <td>{item.createdAt}</td>
                                                                                                </tr>

                                                                                            )
                                                                                        ) :
                                                                                        (
                                                                                            <tr class="responsive-table">
                                                                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                                                    <Bars
                                                                                                        height="70"
                                                                                                        width="100"
                                                                                                        color="#F21313"
                                                                                                        ariaLabel="bars-loading"
                                                                                                        wrapperStyle={{}}
                                                                                                        wrapperClass=""
                                                                                                        visible={true}
                                                                                                    />
                                                                                                </div>
                                                                                            </tr>
                                                                                        )

                                                                                    }




                                                                                </tbody>
                                                                            </table>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                {/* </div>
                                                                </div> */}
                                                            </div>
                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="modal fade" id="exampleModalScrollable" data-backdrop="static" data-keyboard="false">
                                        <div class="modal-dialog modal-dialog-scrollable">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalScrollableTitle"><b>Request Cashout</b><br /><b style={{ color: 'red' }}>[Action not reversible once approved]</b></h5>
                                                    <button type="button" class="close" data-dismiss="modal">
                                                        <i class="far fa-window-close"></i>
                                                    </button>
                                                </div>

                                                <div style={{marginInline:'15px', marginTop: '15px' }}>
                                                    {this.state.load}
                                                    <Notification
                                                        message={{
                                                            type: this.state.type,
                                                            info: this.state.info,
                                                        }}
                                                    />
                                                </div>
                                                <form action='post' onSubmit={this.onRequestCashout}>
                                                    <div class="modal-body">


                                                        <div class="form-row">
                                                            <div class="form-group col-md-12">
                                                                <label class="font-weight-semibold" for="oldPassword">Amount:</label>
                                                                <input type="text" onChange={this.handleAmount} class="form-control" id="stateCity" placeholder="Amount" />
                                                            </div>
                                                            <div class="form-group col-md-12">
                                                                <label class="font-weight-semibold" for="newPassword">Confirm Amount:</label>
                                                                <input type="text" onChange={this.handleAmountNew} class="form-control" id="stateCity" placeholder="Confirm Amount" />
                                                            </div>
                                                            <div class="form-group col-md-12">
                                                                <label class="font-weight-semibold" for="confirmPassword">Authorize with password:</label>
                                                                <input type="password" onChange={this.handlePassword} class="form-control" id="stateCity" placeholder="Confirm Password" />
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-default"
                                                            data-dismiss="modal">Close</button>
                                                        <button disabled={this.state.butt} type="submit" class="btn btn-success">Process Cashout</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                {/* <!-- Content Wrapper END --> */}

                                {/* <!-- Footer START --> */}
                                <footer class="footer">
                                    <div class="footer-content">
                                        <p class="m-b-0">Copyright © 2023 Funug. All rights reserved.</p>
                                        <span>
                                            <a href="#" class="text-gray m-r-15">Term &amp; Conditions</a>
                                            <a href="#" class="text-gray">Privacy &amp; Policy</a>
                                        </span>
                                    </div>
                                </footer>
                                {/* <!-- Footer END --> */}

                            </div>
                            {/* <!-- Page Container END --> */}
                            {/* <!-- Search End--> */}
                        </div>
                    </div>

                </body>

            </>
        )
    }
}
export default Statement


