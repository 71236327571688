import React, { Component } from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import functions from '../../utils/functions';

class Nav extends Component {

    state = {
        userId: functions.sessionGuard(),
        username: functions.loggedInUser(),

    }

    onLogout = () => {
        localStorage.removeItem('funugAdmin@user');
        window.location.replace('/');
    }

    render() {
        const roleList = functions.roleGuard()
        return (
            <>
                {/* <!-- Side Nav START --> */}
                <div class="side-nav">
                    <div class="side-nav-inner">
                        <ul class="side-nav-menu scrollable">


                            <li style={(window.location.pathname === "/" ? { marginTop: '20px', backgroundColor: '#E3EDFE' } : { marginTop: '15px' })} className={(window.location.pathname === "/" ? "active" : "")}>
                                <a href="/">
                                    <span class="icon-holder">
                                        <i class="fas fa-tachometer-alt"></i>
                                    </span>
                                    <span class="title" style={(window.location.pathname === "/" ? { color: '#4189F5' } : null)}>Dashboard</span>


                                </a>
                            </li>
                            <li class="nav-item dropdown {(window.location.pathname === '/my-events' ? 'open' : '')}">
                                <a class="dropdown-toggle" href="javascript:void(0);">
                                    <span class="icon-holder">
                                        <i class="fas fa-th-list"></i>
                                    </span>
                                    <span class="title">Event / Tours</span>
                                    <span class="arrow">
                                        <i class="arrow-icon"></i>
                                    </span>
                                </a>
                                <ul class="dropdown-menu">
                                    <li className={(window.location.pathname === "/my-events" ? "active" : "")}>
                                        <a href="/my-events">All Events</a>
                                    </li>

                                    {window.location.pathname === "/add-event" &&
                                        <li className={(window.location.pathname === "/add-event" ? "active" : "")}>
                                            <a href="/add-event">New Event / Tour</a>
                                        </li>
                                    }
                                </ul>
                            </li>

                            <li class="nav-item dropdown {(window.location.pathname === '/my-events' ? 'open' : '')}">
                                <a class="dropdown-toggle" href="javascript:void(0);">
                                    <span class="icon-holder">
                                        <i class="fas fa-ticket-alt"></i>
                                    </span>
                                    <span class="title">Tickets</span>
                                    <span class="arrow">
                                        <i class="arrow-icon"></i>
                                    </span>
                                </a>
                                <ul class="dropdown-menu">
                                    <li className={(window.location.pathname === "/validate" ? "active" : "")}>
                                        <a href="/validate">Validate Tickets</a>
                                    </li>
                                </ul>
                            </li>
                            {(roleList === "ADMIN"  || roleList === "OPERATION") &&
                                <>
                                    <li class="nav-item dropdown {(window.location.pathname === '/my-events' ? 'open' : '')}">
                                        <a class="dropdown-toggle" href="javascript:void(0);">
                                            <span class="icon-holder">
                                                <i class="fas fa-user-friends"></i>
                                            </span>
                                            <span class="title">Users</span>
                                            <span class="arrow">
                                                <i class="arrow-icon"></i>
                                            </span>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li className={(window.location.pathname === "/list-users" ? "active" : "")}>
                                                <a href="/list-users">All Users</a>
                                            </li>
                                            {window.location.pathname === "/add-user" &&
                                                <li className={(window.location.pathname === "/add-user" ? "active" : "")}>
                                                    <a href="/add-user">Add New Users</a>
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                </>
                            }
                            <li class="nav-item dropdown {(window.location.pathname === '/my-statement' ? 'open' : (window.location.pathname === '/my-sales'? 'open'): '')}">
                                <a class="dropdown-toggle" href="javascript:void(0);">
                                    <span class="icon-holder">
                                        <i class="fas fa-chart-line"></i>
                                    </span>
                                    <span class="title">Finance</span>
                                    <span class="arrow">
                                        <i class="arrow-icon"></i>
                                    </span>
                                </a>
                                <ul class="dropdown-menu {(window.location.pathname === '/my-statement' ? 'open' : '')}">

                                    {roleList === "ADMIN" &&
                                        <li className={(window.location.pathname === "/mobile-money-lists" ? "active" : "")}>
                                            <a href="/mobile-money-lists">Mobile Money</a>
                                        </li>
                                    }

                                    <li className={(window.location.pathname === "/cashouts" ? "active" : "")}>
                                        <a href="/cashouts">Cashouts</a>
                                    </li>
                                    <li className={(window.location.pathname === "/my-sales" ? "active" : "")}>
                                        <a href="/my-sales">Sales</a>
                                    </li>
                                    <li className={(window.location.pathname === "/my-statement" ? "active" : "")}>
                                        <a href="/my-statement">Statement</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="dropdown-toggle" href="javascript:void(0);">
                                    <span class="icon-holder">
                                        <i class="fas fa-cogs"></i>
                                    </span>
                                    <span class="title">Settings</span>
                                    <span class="arrow">
                                        <i class="arrow-icon"></i>
                                    </span>
                                </a>
                                <ul class="dropdown-menu">
                                    <li className={(window.location.pathname === "/account-settings" ? "active" : "")}>
                                        <a href="/account-settings">Account</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <!-- Side Nav END --> */}

            </>
        )
    }
}
export default Nav
